import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BrandService } from "src/app/services/brand.service";
import { Brand } from "src/app/models/brand";
import { Role } from "src/app/constants";
import { UserService } from "src/app/services/user.service";
import swal from 'sweetalert';

declare var $;
@Component({
  selector: "app-view-brand",
  templateUrl: "./view-brand.component.html",
  styleUrls: ["./view-brand.component.css"],
})
export class ViewBrandComponent implements OnInit {
  brandId: number;
  coverImg: String;
  logoImg: String;
  brandName: String;
  email: String;
  phoneNumber: String;
  hashTags: String;
  description: String;
  hours: any;
  isBrandEditable: Boolean = false;
  isVisible: boolean = true;
  isVisibleAnalytics: boolean = false;
  isBrandRemoveable: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private brandService: BrandService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.brandId = params.id;
      this.brandService.getBrandById(params["id"]).subscribe((brand: Brand) => {
        this.coverImg = brand.cover_photo;
        this.logoImg = brand.logo;
        this.brandName = brand.name;
        this.email = brand.email;
        if (brand.hash_tags) {
          this.hashTags = brand.hash_tags.toString();
        }
        this.description = brand.description;
        this.phoneNumber = brand.phone;
        if (brand.opening_hours && brand.opening_hours.toString() != "") {
          this.hours = JSON.parse(brand.opening_hours.toString());
        }
        this.userService.getUser().subscribe((user) => {

          if (user.role == Role.SUPER_ADMIN) {
            this.isBrandRemoveable = true;
            this.isVisibleAnalytics = true;
          }

          if(user.role == Role.BRAND_USER) {
            this.isVisible = false;
          }

          if (user.role == Role.SUPER_ADMIN || user.id == brand.user_id) {
            this.isBrandEditable = true;
          } else {
            this.isBrandEditable = false;
          }
        });
      });
    });
    // this.coverImg = "https://www.incimages.com/uploaded_files/image/1940x900/getty_509107562_2000133320009280346_351827.jpg"
    const input: any = {};
    //   $('.clockpicker').clockpicker({
    //       donetext: 'Done'
    //       ,
    //   }).find('input').change(function () {
    //   });
    $("#check-minutes").click(function (e) {
      // Have to stop propagation here
      e.stopPropagation();
      input.clockpicker("show").clockpicker("toggleView", "minutes");
    });
    if (/mobile/i.test(navigator.userAgent)) {
      $("input").prop("readOnly", true);
    }
    $(".input-daterange-timepicker").daterangepicker({
      timePicker: true,
      format: "MM/DD/YYYY h:mm A",
      timePickerIncrement: 30,
      timePicker12Hour: true,
      timePickerSeconds: false,
      buttonClasses: ["btn", "btn-sm"],
      applyClass: "btn-danger",
      cancelClass: "btn-inverse",
    });
    $(".input-limit-datepicker").daterangepicker({
      format: "MM/DD/YYYY",
      minDate: "06/01/2015",
      maxDate: "06/30/2015",
      buttonClasses: ["btn", "btn-sm"],
      applyClass: "btn-danger",
      cancelClass: "btn-inverse",
      dateLimit: {
        days: 6,
      },
    });
  }


  removeBrand() {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this file!",
      icon: "warning",
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        this.brandService.deleteBrandById(this.brandId).subscribe((res) => {
          if(res.success) {
            swal("Your file has been deleted!", {
              icon: "success",
            });
            this.router.navigateByUrl("/brands");
          } else {
            swal(res.message);
          }
        })
      }
    });
  }


  viewUsers() {
    this.route.params.subscribe((params) => {
      this.router.navigateByUrl("/brand-users/" + params["id"]);
    });
  }
  viewBanners() {
    this.route.params.subscribe((params) => {
      this.router.navigateByUrl("/ads/" + params["id"]);
    });
  }
  viewProducts() {
    this.route.params.subscribe((params) => {
      this.router.navigateByUrl("/products/" + params["id"]);
    });
  }
  viewOffer() {
    this.route.params.subscribe((params) => {
      this.router.navigateByUrl("/offers/" + params["id"]);
    });
  }
  editBrand() {
    this.route.params.subscribe((params) => {
      this.router.navigateByUrl("/edit-brand/" + params["id"]);
    });
  }
}
