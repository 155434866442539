import {Component, OnInit} from '@angular/core';
import {User} from "../../../models/user";
import {BannerService, EditBannerRequest} from "../../../services/banner.service";
import {UserService} from "../../../services/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Banner} from "../../../models/banner";
import {Role} from "../../../constants";
import {Util} from "../../../util";

@Component({
  selector: 'app-edit-banner',
  templateUrl: './edit-banner.component.html',
  styleUrls: ['./edit-banner.component.css']
})

export class EditBannerComponent implements OnInit {
  showErrors = false;
  status: string = null;
  formData: EditBannerRequest;
  user: User;

  public minDate: Date = new Date();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private bannerService: BannerService
  ) {
  }

  ngOnInit() {
    this.userService.getUser().subscribe(user => {
      this.user = user;
      this.route.params.subscribe(params => {
        if (params.banner_id) {
          this.bannerService.getBannerById(params.banner_id).subscribe(banner => {
            this.initEditExistingBanner(banner);
          });
        } else if ((user.role === Role.SUPER_ADMIN || user.role === Role.ADMIN_USER) && params.brand_id){
          this.initCreatingNewBanner(params.brand_id);
        } else if (user.role === Role.BRAND_ADMIN) {
          this.initCreatingNewBanner(this.user.brand.id);
        }
      });
    });
  }

  private initEditExistingBanner(banner) {
    this.formData = {
      brand_id: banner.brand_id,
      title: banner.title,
      description: banner.description,
      url: banner.url,
      status: banner.status,
      type: banner.type,
      photo: banner.photo,
      start_date: banner.start_date,
      end_date: banner.end_date
    };
    if(0 != this.router.url.indexOf("/duplicate-ads")) {
      this.formData.id = banner.id;
    }
  }

  private initCreatingNewBanner(brand_id) {
    this.formData = {
      brand_id: brand_id,
      title: "",
      description: "",
      url: "",
      status: "PENDING",
      type: "",
      photo: null,
      start_date: null,
      end_date: null
    };
  }

  private validateBanner() {
    this.showErrors = true;

    if (!this.formData.title || !this.formData.description || !this.formData.url || !this.formData.status || !this.formData.type || !this.formData.start_date || !this.formData.end_date) {
      return false;
    }

    this.showErrors = false;
    
    return true;
  }

  handleFileInput(files: FileList) {

    var file = files[0];

    if (files && file) {
        var reader = new FileReader();

        reader.onload = this._handleReaderLoaded.bind(this);

        reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
      var base64textString= btoa(binaryString);
      this.bannerService.uploadBannerImage(base64textString).subscribe(url => {
      this.formData.photo = url.url;
    });
   }

  saveBanner() {
    if (this.validateBanner()) {
      this.status = 'saving';
      this.bannerService.editBanner(this.formData).subscribe((banner: Banner) => {
        this.status = 'saved';
        this.showErrors = false;
        setTimeout(() => {
          this.router.navigateByUrl('/ad/' + banner.id);
        }, 1000);
      });
    }
  }

  fileName(url) {
    return Util.getFileName(url)
  }
}
