import {BrowserModule} from '@angular/platform-browser';
import {NgModule, Provider, Type} from '@angular/core';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {LoginLayoutComponent} from './components/basic/login-layout/login-layout.component';
import {LoginComponent} from './components/basic/login/login.component';
import {LayoutComponent} from './components/basic/layout/layout.component';
import {NavBarComponent} from './components/basic/nav-bar/nav-bar.component';
import {MenuComponent} from './components/basic/menu/menu.component';
import {AllBrandsComponent} from './components/brand/all-brands/all-brands.component';
import {ViewBrandComponent} from './components/brand/view-brand/view-brand.component';
import {EditBrandComponent} from './components/brand/edit-brand/edit-brand.component';
import {AllCategoriesComponent} from './components/category/all-categories/all-categories.component';
import {ViewCategoryComponent} from './components/category/view-category/view-category.component';
import {EditCategoryComponent} from './components/category/edit-category/edit-category.component';
import {AllProductsComponent} from './components/product/all-products/all-products.component';
import {ViewProductComponent} from './components/product/view-product/view-product.component';
import {EditProductComponent} from './components/product/edit-product/edit-product.component';
import {AllBannersComponent} from './components/banner/all-banners/all-banners.component';
import {EditBannerComponent} from './components/banner/edit-banner/edit-banner.component';
import {ViewBannerComponent} from './components/banner/view-banner/view-banner.component';
import {ViewOfferComponent} from './components/offer/view-offer/view-offer.component';
import {EditOfferComponent} from './components/offer/edit-offer/edit-offer.component';
import {AllOffersComponent} from './components/offer/all-offers/all-offers.component';
import {AllBrandUsersComponent} from './components/admin/all-brand-users/all-brand-users.component';
import {EditBrandUserComponent} from './components/admin/edit-brand-user/edit-brand-user.component';
import {EditAdminUserComponent} from './components/admin/edit-admin-user/edit-admin-user.component';
import {AllAdminUsersComponent} from './components/admin/all-admin-users/all-admin-users.component';
import {DashboardComponent} from './components/basic/dashboard/dashboard.component';
import {NotFoundComponent} from './components/basic/not-found/not-found.component';
import {RouterModule} from "@angular/router";
import {FooterComponent} from './components/basic/footer/footer.component';
import {ProfileComponent} from "./components/admin/profile/profile.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {DROPZONE_CONFIG, DropzoneConfigInterface, DropzoneModule} from "ngx-dropzone-wrapper";
import {AppConfig} from "./config";
import {CustomInterceptor} from "./custom-interceptor";
import {NgxPaginationModule} from "ngx-pagination";
import { JwtHelperService } from '@auth0/angular-jwt';
import { JwtModule } from '@auth0/angular-jwt';
import {DatePickerModule} from "@syncfusion/ej2-angular-calendars";
import {NotifierModule} from "angular-notifier";
import {customNotifierOptions} from "./notifier-options";
import { PendingProductsComponent } from './components/admin/pending-products/pending-products.component';
import { PendingBannersComponent } from './components/admin/pending-banners/pending-banners.component';
import { PendingOffersComponent } from './components/admin/pending-offers/pending-offers.component';
import { AnalyticsComponent } from './components/admin/analytics/analytics.component';
import { OverallAnalyticsComponent } from './components/admin/overall-analytics/overall-analytics.component';


const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  url: AppConfig.apiUrl('upload'),
  maxFiles: 4,
  acceptedFiles: 'image/*',
  headers: {
    "Authorization": "Bearer " + localStorage.getItem('token')
  }
};

export function tokenGetterFunction() {
  return localStorage.getItem('token');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginLayoutComponent,
    LoginComponent,
    LayoutComponent,
    NavBarComponent,
    MenuComponent,
    AllBrandsComponent,
    ViewBrandComponent,
    EditBrandComponent,
    AllCategoriesComponent,
    ViewCategoryComponent,
    EditCategoryComponent,
    AllProductsComponent,
    ViewProductComponent,
    EditProductComponent,
    AllBannersComponent,
    EditBannerComponent,
    ViewBannerComponent,
    ViewOfferComponent,
    EditOfferComponent,
    AllOffersComponent,
    AllBrandUsersComponent,
    EditBrandUserComponent,
    EditAdminUserComponent,
    AllAdminUsersComponent,
    DashboardComponent,
    NotFoundComponent,
    FooterComponent,
    ProfileComponent,
    PendingProductsComponent,
    PendingBannersComponent,
    PendingOffersComponent,
    AnalyticsComponent,
    OverallAnalyticsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    HttpClientModule,
    DropzoneModule,
    NgxPaginationModule,
    JwtModule.forRoot({
			config: {
				tokenGetter: tokenGetterFunction
			}
		}),
    DatePickerModule,
    NotifierModule.withConfig(customNotifierOptions)
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: CustomInterceptor, multi: true},
    {provide: DROPZONE_CONFIG, useValue: DEFAULT_DROPZONE_CONFIG},
    JwtHelperService
  ],
  bootstrap: [AppComponent]
})



export class AppModule {


}
