import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { EditUserRequest, UserService } from "../../../services/user.service";
import { User } from "../../../models/user";
import { Category } from "../../../models/category";
import {
  BrandService,
  EditBrandRequest,
} from "../../../services/brand.service";
import { Brand } from "../../../models/brand";
import { Role } from "../../../constants";
import { Util } from "../../../util";
import { ValidateService } from "src/app/services/validate.service";

declare var jQuery;
declare var input;
declare var $;

@Component({
  selector: "app-edit-brand",
  templateUrl: "./edit-brand.component.html",
  styleUrls: ["./edit-brand.component.css"],
})
export class EditBrandComponent implements OnInit {
  showErrors = false;
  status: string = null;
  brandData: EditBrandRequest;
  userData: EditUserRequest;
  user: User;
  categories: Category[];
  logo: any = "";
  cover_photo: any = "";
  isSuperAdmin = false;
  isValidUserEmail = true;
  isValidBrandEmail = true;
  isValidPassword = true;
  isValidUrl = true;
  openHours = [
    { day: "Monday", hours: [{ start: "0.00", end: "0.00" }], active: false },
    { day: "Tuesday", hours: [{ start: "0.00", end: "0.00" }], active: false },
    {
      day: "Wednesday",
      hours: [{ start: "0.00", end: "0.00" }],
      active: false,
    },
    { day: "Thursday", hours: [{ start: "0.00", end: "0.00" }], active: false },
    { day: "Friday", hours: [{ start: "0.00", end: "0.00" }], active: false },
    { day: "Saturday", hours: [{ start: "0.00", end: "0.00" }], active: false },
    { day: "Sunday", hours: [{ start: "0.00", end: "0.00" }], active: false },
  ];
  base64: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private brandService: BrandService,
    private validateService: ValidateService
  ) {}

  ngOnInit() {
    this.userService.getUser().subscribe((user) => {
      this.isSuperAdmin = user.role == Role.SUPER_ADMIN || user.role == Role.ADMIN_USER;
      this.route.params.subscribe((params) => {
        const brandId = Util.getBrandIdBasedOnRole(user, params);
        if (brandId) {
          this.brandService.getBrandById(brandId).subscribe((brand) => {
            if (brand.user_id) {
              this.userService
                .getBrandAdminByUserId(brand.user_id)
                .subscribe((user: any) => {
                  this.initEditExistingBrand(user, brand);
                });
            } else {
              this.initEditExistingBrand(null, brand);
            }
          });
        } else {
          this.initCreatingNewBrand();
        }
      });
    });
  }

  private initEditExistingBrand(user: User, brand: Brand) {
    if (user) {
      this.userData = {
        id: user.id,
        name: user.name,
        email: user.email,
        password: "",
        role: Role.BRAND_ADMIN,
      };
    } else {
      this.userData = {
        name: "",
        email: "",
        password: "",
        role: Role.BRAND_ADMIN,
      };
    }
    this.brandData = {
      id: brand.id,
      user_id: brand.user_id,
      name: brand.name,
      phone: brand.phone,
      address: brand.address,
      email: brand.email,
      website: brand.website,
      description: brand.description,
      plan_type: 1,
      logo: brand.logo,
      cover_photo: brand.cover_photo,
      hash_tags: brand.hash_tags,
      category: brand.category,
    };
    this.initClockPickers();
    this.setOpeningHoursToDisplay(brand.opening_hours);
  }

  private initCreatingNewBrand() {
    this.userData = {
      name: "",
      email: "",
      password: "",
      role: Role.BRAND_ADMIN,
    };
    this.brandData = {
      user_id: null,
      name: "",
      phone: "",
      address: "",
      email: "",
      website: "",
      description: "",
      plan_type: 1,
      logo: "",
      cover_photo: "",
      hash_tags: "",
      category: "",
    };
    this.initClockPickers();
  }

  openHoursSelectionChanged() {
    this.initClockPickers();
  }

  initClockPickers() {
    const self = this;
    // setTimeout(() => {
    //   const clockPicker = $('.clockpicker');
    //   //clockPicker.unbind();
    //   clockPicker.clockpicker({
    //     donetext: 'Done'
    //     ,
    //   }).find('input').change(function () {
    //     const [, dayIndex, hourIndex, type] = this.id.split("_");
    //     self.openHours[dayIndex]['hours'][hourIndex][type] = this.value;
    //   });
    // }, 500);
  }

  removeOpeningHourItem(dayIndex, hourIndex) {
    this.openHours[dayIndex]["hours"].splice(hourIndex, 1);
  }

  addOpeningHourItem(dayIndex) {
    this.openHours[dayIndex]["hours"].push({ start: "0.00", end: "0.00" });
    this.initClockPickers();
  }

  setOpeningHoursToSave() {
    const openHours = [];

    for (let i = 0; i < this.openHours.length; i++) {
      const day = $("#day_" + i);

      if (day.is(":checked")) {
        const hours = [];
        const numItems = $(".hourly_section_" + i).length;

        for (let j = 0; j < numItems; j++) {
          const start_hour = $("#start_" + i + "_" + j).val();
          const end_hour = $("#end_" + i + "_" + j).val();

          hours.push({ start: start_hour, end: end_hour });
        }

        openHours.push({ day: this.openHours[i].day, hours: hours });
      }
    }

    this.brandData.opening_hours = JSON.stringify(openHours);
  }

  setOpeningHoursToDisplay(openingHours) {
    if (!openingHours) {
      return;
    }
    const data = JSON.parse(openingHours);
    for (let i = 0, len = this.openHours.length; i < len; i++) {
      const item = data.find((j) => j.day === this.openHours[i].day);
      if (item) {
        this.openHours[i]["active"] = true;
        this.openHours[i]["hours"] = item.hours;
      } else {
        this.openHours[i]["active"] = false;
      }
    }
  }

  readLogoURL(files: File): void {
    if (files && files[0]) {
      const file = files[0];

      var iu;

      const reader = new FileReader();
      reader.onload = (e) => (iu = reader.result);
      reader.readAsDataURL(file);

      const reader2 = new FileReader();
      reader2.onload = this._handleLogoLoaded.bind(this);
      reader2.readAsBinaryString(file);
      //this.fileCount++;
    }
  }

  readCoverURL(files: File): void {
    if (files && files[0]) {
      const file = files[0];

      var iu;

      const reader = new FileReader();
      reader.onload = (e) => (iu = reader.result);
      reader.readAsDataURL(file);

      const reader2 = new FileReader();
      reader2.onload = this._handleCoverLoaded.bind(this);
      reader2.readAsBinaryString(file);
      //this.fileCount++;
    }
  }

  _handleLogoLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    var base64textString = btoa(binaryString);

    this.brandService.uploadBrandLogo(base64textString).subscribe((url) => {
      this.brandData.logo = url.url;
    });
  }

  _handleCoverLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    var base64textString = btoa(binaryString);

    this.brandService.uploadBrandCover(base64textString).subscribe((url) => {
      this.brandData.cover_photo = url.url;
    });
  }

  // handleFileInputLogo(files: FileList) {
  //   this.brandService.uploadBrandLogo(files.item(0)).subscribe(url => {
  //     this.brandData.logo = url.url;
  //   });
  // }

  // handleFileInputCover(files: FileList) {
  //   this.brandService.uploadBrandCover(files.item(0)).subscribe(url => {
  //     this.brandData.cover_photo = url.url;
  //   });
  // }

  async saveBrand() {
    try {
      this.setOpeningHoursToSave();
      this.showErrors = true;

      if (!this.validateEmail(this.userData.email, "user")) {
        return;
      }
      if (!this.userData.id && !this.validatePassword(this.userData.password)) {
        return;
      }
      if (!this.validateEmail(this.brandData.email, "brand")) {
        return;
      }
      if (!this.validateUser()) {
        return;
      }
      if (!this.validateBrand()) {
        return;
      }

      this.status = "saving";
      const brandAdmin: any = await this.userService
        .editUser(this.userData)
        .toPromise();
      this.brandData.user_id = brandAdmin.id;
      
      // this.brandData.website = `${this.brandData.website}`

      const brand = await this.brandService
        .editBrand(this.brandData)
        .toPromise();
      this.status = "saved";
      this.showErrors = false;
      setTimeout(() => {
        this.router.navigateByUrl("/brand/" + brand.id);
      }, 1000);
    } catch (e) {
      this.status = null;
    }
  }

  validateUser() {
    if (!this.userData.email) {
      return false;
    }
    if (!this.userData.name) {
      return false;
    }
    if (!this.userData.password && !this.userData.id) {
      return false;
    }
    return true;
  }

  validateEmail(event, type) {
    if (!this.validateService.validateEmail(event)) {
      if (type == "user") {
        this.isValidUserEmail = false;
      } else {
        this.isValidBrandEmail = false;
      }
      return false;
    } else {
      if (type == "user") {
        this.isValidUserEmail = true;
      } else {
        this.isValidBrandEmail = true;
      }
      return true;
    }
  }

  validatePassword(event) {
    this.isValidPassword = this.validateService.validatePassword(event);
    return this.isValidPassword;
  }

  validateBrand() {
    if (!this.brandData.name) {
      return false;
    }
    if (!this.brandData.phone) {
      return false;
    }
    if (!this.brandData.address) {
      return false;
    }
    if (!this.brandData.email) {
      return false;
    }
    if (!this.brandData.website) {
      return false;
    }
    if (!this.validateUrl()) {
      return false;
    }
    if (!this.brandData.description) {
      return false;
    }
    if (!this.brandData.plan_type) {
      return false;
    }
    if (!this.brandData.logo) {
      return false;
    }
    if (!this.brandData.cover_photo) {
      return false;
    }
    if (!this.brandData.category) {
      return false;
    }

    return true;
  }

  fileName(url) {
    return Util.getFileName(url);
  }

  validateUrl() {
    this.isValidUrl = this.validateService.validateUrl(this.brandData.website);
    return this.isValidUrl;
  }
}
