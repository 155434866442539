import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {UserService} from 'src/app/services/user.service';
import {Role} from 'src/app/constants';
import {Util} from "../../../util";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  brandId;
  canViewSingleBrand=false;
  canManageSingleBrand=false;
  canViewAllBrands=false;
  canManageAllBrands=false;

  constructor(private router: Router, private userService: UserService) { }

  ngOnInit() {
    this.userService.getUser().subscribe(user => {
      if (user.role == Role.SUPER_ADMIN) {
        this.canManageAllBrands = true;
      }
      if (user.role == Role.SUPER_ADMIN || user.role == Role.ADMIN_USER) {
        this.canViewAllBrands = true;
      }
      if (user.role == Role.BRAND_ADMIN && user.brand && user.brand.id) {
        this.brandId = user.brand.id;
        this.canManageSingleBrand = true;
      }
      if (user.role == Role.BRAND_ADMIN || user.role == Role.BRAND_USER) {
        this.brandId = Util.getBrandIdBasedOnRole(user, null);
        if(this.brandId) {
          this.canViewSingleBrand = true;
        }
      }
    });
  }

  logOut() {
    this.userService.logout();
    this.router.navigate(['/login']);
  }

}
