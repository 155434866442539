import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {UserService} from "../../../services/user.service";
import {User} from "../../../models/user";
declare var $

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {

  user: User;

  constructor(private router: Router, private userService: UserService) {
  }

  ngOnInit() {
    this.userService.getUser().subscribe((res: any) => {
      this.user = res;
      // this.user = res['data'];
    });

    $(document).ready(function(){
      $('.open-close').click(function(){
        $("body").toggleClass("show-sidebar").toggleClass("hide-sidebar"),$(".sidebar-head .open-close i").toggleClass("ti-menu")}),$(".right-side-toggle").click(function(){$(".right-sidebar").slideDown(50),$(".right-sidebar").toggleClass("shw-rside"),$(".fxhdr").click(function(){$("body").toggleClass("fix-header")}),$(".fxsdr").click(function(){$("body").toggleClass("fix-sidebar")})
      })
    })
  }

  logOut() {
    this.userService.logout();
    this.router.navigate(['/login']);
  }

  getNameInitials(name: String): String {
    if(name) {
      const trimedName = name.trim();
      return trimedName.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '').toUpperCase();
    } else {
      return "-";
    }
  }

  getShortName(name: String): String {
    if(name) {
      const trimedName = name.trim();
      return trimedName.length < 10 ? trimedName : (trimedName.split(" ")[0].length < 10) ? trimedName.split(" ")[0] : 'Hi User';
    } else {
      return "Hi User";
    }
  }

}
