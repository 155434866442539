import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { AppConfig } from "../../../config";
import { DropzoneComponent, DropzoneConfigInterface, } from "ngx-dropzone-wrapper";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "../../../services/user.service";
import { User } from "../../../models/user";
import { EditProductRequest, ProductService, } from "../../../services/product.service";
import { CategoryService } from "../../../services/category.service";
import { Product } from "../../../models/product";
import { Category } from "../../../models/category";
import { Role } from "../../../constants";
import { ValidateService } from "../../../services/validate.service";
import { NotifierService } from "angular-notifier";

declare var $;

@Component({
  selector: "app-edit-product",
  templateUrl: "./edit-product.component.html",
  styleUrls: ["./edit-product.component.css"],
  encapsulation: ViewEncapsulation.None,
})

export class EditProductComponent implements OnInit {
  showErrors = false;
  status: string = null;
  formData: EditProductRequest;
  user: User;
  categories: Category[];
  validations = {
    validUrl: true,
  };

  imageSrc = [];

  fileCount = 0;

  maxFiles = 4;
  
  dropzoneConfig: DropzoneConfigInterface = {
    url: AppConfig.apiUrl("upload/product"),
    maxFiles: this.maxFiles,
    addRemoveLinks: true,
    acceptedFiles: "image/*",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  // currencyList: string[] = [
  //   {
  //     'symbol': "",
  //     "currency": "EUR",
  //   }
  // ];

  currencyList = [
    {"code":"EUR","symbol":"€"},
    {"code":"USD","symbol":"$"},
    {"code":"GBP","symbol":"£"},
    {"code":"AFA","symbol":"؋"},
    {"code":"ALL","symbol":"Lek"},
    {"code":"DZD","symbol":"دج"},
    {"code":"AOA","symbol":"Kz"},
    {"code":"ARS","symbol":"$"},
    {"code":"AMD","symbol":"֏"},
    {"code":"AWG","symbol":"ƒ"},
    {"code":"AUD","symbol":"$"},
    {"code":"AZN","symbol":"m"},
    {"code":"BSD","symbol":"B$"},
    {"code":"BHD","symbol":".د.ب"},
    {"code":"BDT","symbol":"৳"},
    {"code":"BBD","symbol":"Bds$"},
    {"code":"BYR","symbol":"Br"},
    {"code":"BEF","symbol":"fr"},
    {"code":"BZD","symbol":"$"},
    {"code":"BMD","symbol":"$"},
    {"code":"BTN","symbol":"Nu."},
    {"code":"BTC","symbol":"฿"},
    {"code":"BOB","symbol":"Bs."},
    {"code":"BAM","symbol":"KM"},
    {"code":"BWP","symbol":"P"},
    {"code":"BRL","symbol":"R$"},
    {"code":"BND","symbol":"B$"},
    {"code":"BGN","symbol":"Лв."},
    {"code":"BIF","symbol":"FBu"},
    {"code":"KHR","symbol":"KHR"},
    {"code":"CAD","symbol":"$"},
    {"code":"CVE","symbol":"$"},
    {"code":"KYD","symbol":"$"},
    {"code":"XOF","symbol":"CFA"},
    {"code":"XAF","symbol":"FCFA"},
    {"code":"XPF","symbol":"₣"},
    {"code":"CLP","symbol":"$"},
    {"code":"CNY","symbol":"¥"},
    {"code":"COP","symbol":"$"},
    {"code":"KMF","symbol":"CF"},
    {"code":"CDF","symbol":"FC"},
    {"code":"CRC","symbol":"₡"},
    {"code":"HRK","symbol":"kn"},
    {"code":"CUC","symbol":"$, CUC"},
    {"code":"CZK","symbol":"Kč"},
    {"code":"DKK","symbol":"Kr."},
    {"code":"DJF","symbol":"Fdj"},
    {"code":"DOP","symbol":"$"},
    {"code":"XCD","symbol":"$"},
    {"code":"EGP","symbol":"ج.م"},
    {"code":"ERN","symbol":"Nfk"},
    {"code":"EEK","symbol":"kr"},
    {"code":"ETB","symbol":"Nkf"},
    {"code":"FKP","symbol":"£"},
    {"code":"FJD","symbol":"FJ$"},
    {"code":"GMD","symbol":"D"},
    {"code":"GEL","symbol":"ლ"},
    {"code":"DEM","symbol":"DM"},
    {"code":"GHS","symbol":"GH₵"},
    {"code":"GIP","symbol":"£"},
    {"code":"GRD","symbol":"₯, Δρχ, Δρ"},
    {"code":"GTQ","symbol":"Q"},
    {"code":"GNF","symbol":"FG"},
    {"code":"GYD","symbol":"$"},
    {"code":"HTG","symbol":"G"},
    {"code":"HNL","symbol":"L"},
    {"code":"HKD","symbol":"$"},
    {"code":"HUF","symbol":"Ft"},
    {"code":"ISK","symbol":"kr"},
    {"code":"INR","symbol":"₹"},
    {"code":"IDR","symbol":"Rp"},
    {"code":"IRR","symbol":"﷼"},
    {"code":"IQD","symbol":"د.ع"},
    {"code":"ILS","symbol":"₪"},
    {"code":"ITL","symbol":"L,£"},
    {"code":"JMD","symbol":"J$"},
    {"code":"JPY","symbol":"¥"},
    {"code":"JOD","symbol":"ا.د"},
    {"code":"KZT","symbol":"лв"},
    {"code":"KES","symbol":"KSh"},
    {"code":"KWD","symbol":"ك.د"},
    {"code":"KGS","symbol":"лв"},
    {"code":"LAK","symbol":"₭"},
    {"code":"LVL","symbol":"Ls"},
    {"code":"LBP","symbol":"£"},
    {"code":"LSL","symbol":"L"},
    {"code":"LRD","symbol":"$"},
    {"code":"LYD","symbol":"د.ل"},
    {"code":"LTL","symbol":"Lt"},
    {"code":"MOP","symbol":"$"},
    {"code":"MKD","symbol":"ден"},
    {"code":"MGA","symbol":"Ar"},
    {"code":"MWK","symbol":"MK"},
    {"code":"MYR","symbol":"RM"},
    {"code":"MVR","symbol":"Rf"},
    {"code":"MRO","symbol":"MRU"},
    {"code":"MUR","symbol":"₨"},
    {"code":"MXN","symbol":"$"},
    {"code":"MDL","symbol":"L"},
    {"code":"MNT","symbol":"₮"},
    {"code":"MAD","symbol":"MAD"},
    {"code":"MZM","symbol":"MT"},
    {"code":"MMK","symbol":"K"},
    {"code":"NAD","symbol":"$"},
    {"code":"NPR","symbol":"₨"},
    {"code":"ANG","symbol":"ƒ"},
    {"code":"TWD","symbol":"$"},
    {"code":"NZD","symbol":"$"},
    {"code":"NIO","symbol":"C$"},
    {"code":"NGN","symbol":"₦"},
    {"code":"KPW","symbol":"₩"},
    {"code":"NOK","symbol":"kr"},
    {"code":"OMR","symbol":".ع.ر"},
    {"code":"PKR","symbol":"₨"},
    {"code":"PAB","symbol":"B/."},
    {"code":"PGK","symbol":"K"},
    {"code":"PYG","symbol":"₲"},
    {"code":"PEN","symbol":"S/."},
    {"code":"PHP","symbol":"₱"},
    {"code":"PLN","symbol":"zł"},
    {"code":"QAR","symbol":"ق.ر"},
    {"code":"RON","symbol":"lei"},
    {"code":"RUB","symbol":"₽"},
    {"code":"RWF","symbol":"FRw"},
    {"code":"SVC","symbol":"₡"},
    {"code":"WST","symbol":"SAT"},
    {"code":"SAR","symbol":"﷼"},
    {"code":"RSD","symbol":"din"},
    {"code":"SCR","symbol":"SRe"},
    {"code":"SLL","symbol":"Le"},
    {"code":"SGD","symbol":"$"},
    {"code":"SKK","symbol":"Sk"},
    {"code":"SBD","symbol":"Si$"},
    {"code":"SOS","symbol":"Sh.so."},
    {"code":"ZAR","symbol":"R"},
    {"code":"KRW","symbol":"₩"},
    {"code":"XDR","symbol":"SDR"},
    {"code":"LKR","symbol":"Rs"},
    {"code":"SHP","symbol":"£"},
    {"code":"SDG","symbol":".س.ج"},
    {"code":"SRD","symbol":"$"},
    {"code":"SZL","symbol":"E"},
    {"code":"SEK","symbol":"kr"},
    {"code":"CHF","symbol":"CHf"},
    {"code":"SYP","symbol":"LS"},
    {"code":"STD","symbol":"Db"},
    {"code":"TJS","symbol":"SM"},
    {"code":"TZS","symbol":"TSh"},
    {"code":"THB","symbol":"฿"},
    {"code":"TOP","symbol":"$"},
    {"code":"TTD","symbol":"$"},
    {"code":"TND","symbol":"ت.د"},
    {"code":"TRY","symbol":"₺"},
    {"code":"TMT","symbol":"T"},
    {"code":"UGX","symbol":"USh"},
    {"code":"UAH","symbol":"₴"},
    {"code":"AED","symbol":"إ.د"},
    {"code":"UYU","symbol":"$"},
    {"code":"UZS","symbol":"лв"},
    {"code":"VUV","symbol":"VT"},
    {"code":"VEF","symbol":"Bs"},
    {"code":"VND","symbol":"₫"},
    {"code":"YER","symbol":"﷼"},
    {"code":"ZMK","symbol":"ZK"}
  ];

  @ViewChild("DropZone") componentRef: DropzoneComponent;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private productService: ProductService,
    private categoryService: CategoryService,
    private validateService: ValidateService,
    private notifierService: NotifierService
  ) {}

  ngOnInit() {
    this.userService.getUser().subscribe((user) => {
      this.user = user;
      this.route.params.subscribe((params) => {
        if (params.product_id) {
          this.productService
            .getProductById(params.product_id)
            .subscribe((product) => {
              this.categoryService
                .getCategoriesForBrand(product.brand_id)
                .subscribe((categories) => {
                  this.categories = categories;
                  this.initEditExistingProduct(product);
                });
            });
        } else {
          if (params.brand_id) {
            this.categoryService
              .getCategoriesForBrand(params.brand_id)
              .subscribe((categories) => {
                this.categories = categories;
                this.initCreatingNewProduct(params.brand_id);
              });
          } else if (user.role == Role.BRAND_ADMIN) {
            this.categoryService
              .getCategoriesForBrand(user.brand.id)
              .subscribe((categories) => {
                this.categories = categories;
                this.initCreatingNewProduct(user.brand.id);
              });
          }
        }
      });
    });
  }

  private initEditExistingProduct(product: Product) {    
    this.formData = {
      id: product.id,
      brand_id: product.brand_id,
      category_id: product.category_id,
      description: product.description,
      photos: product.photos,
      currency: product.currency,
      price: product.price,
      title: product.title,
      url: product.url
    };

    this.setUploadImages(product);
  }

  private initCreatingNewProduct(brandId: number) {
    this.formData = {
      brand_id: brandId,
      category_id: null,
      description: "",
      photos: [],
      price: null,
      currency:  "€",
      title: "",
      url: "",
    };
  }

  setUploadImages(product) {
    this.fileCount = this.formData.photos.length;

    for (const photo of this.formData.photos) {
      this.imageSrc.push(photo.url);
    }
  }

  removeProductPhoto(index: number) {
    this.imageSrc.splice(index, 1);
    this.fileCount--;
  }

  saveProduct() {
    if (this.validateProduct()) {
      this.status = "saving";

      this.formData.photos = this.imageSrc;

      this.productService.editProduct(this.formData).subscribe((res: any) => {
          if(res.success) {
            this.status = "saved";
            this.showErrors = false;
            
            this.productService.currentListedBrand = null;
            this.productService.currentListedProducts = [];
            this.router.navigateByUrl("/products/" + res.data.brand_id);
          } else {
            this.status = null;
            this.notifierService.notify('error', res.message);
          }
        });
    }
  }

  readURL(files: File): void {
    if (files && files[0]) {
      const file = files[0];

      var iu;

      const reader = new FileReader();
      reader.onload = (e) => (iu = reader.result);
      reader.readAsDataURL(file);

      const reader2 = new FileReader();
      reader2.onload = this._handleReaderLoaded.bind(this);
      reader2.readAsBinaryString(file);
      this.fileCount++;
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    var base64textString:any = btoa(binaryString);

    this.productService.uploadProductImage(base64textString).subscribe((url) => {
      this.formData.photos.push(base64textString);
      this.imageSrc.push(url.url);
    });
  }

  private validateProduct() {
    this.showErrors = true;
    if (!this.formData.title) {
      return false;
    }

    if (this.formData.photos.length === 0) {
      return false;
    }

    if (!this.formData.description) {
      return false;
    }

    if (!this.formData.price) {
      return false;
    }

    if (!this.formData.url) {
      return false;
    }

    if (!this.validateUrl()) {
      return false;
    }

    if (!this.formData.category_id) {
      return false;
    }

    this.showErrors = false;
    return true;
  }

  validateUrl() {
    this.formData.url = this.formData.url.replace(/\s/gm, '');

    this.validations.validUrl = this.validateService.validateUrl(
      this.formData.url
    );

    return this.validations.validUrl;
  }
}
