import { Component, OnInit } from '@angular/core';
import {Role} from "../../../constants";
import {User} from "../../../models/user";
import {EditAdminUserRequest, UserService} from "../../../services/user.service";
import {NotifierService} from "angular-notifier";
import {ActivatedRoute, Router} from "@angular/router";
import { ValidateService } from 'src/app/services/validate.service';

@Component({
  selector: 'app-edit-admin-user',
  templateUrl: './edit-admin-user.component.html',
  styleUrls: ['./edit-admin-user.component.css']
})
export class EditAdminUserComponent implements OnInit {

  show_errors = false;
  status: string = null;
  formData: EditAdminUserRequest;
  isValidEmail:Boolean = false
  isValidPassword: Boolean = false
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private notifierService: NotifierService,
    private validateService: ValidateService
  ) { }

  ngOnInit() {
    this.userService.getUser().subscribe(user => {
      this.route.params.subscribe( params => {
        if(params.admin_user_id) {
          this.userService.getAdminUserByUserId(params.admin_user_id).subscribe((adminUser: any) => {
            this.initEditExistingAdminUser(adminUser);
          });
        } else if(user.role === Role.SUPER_ADMIN) {
          this.initCreatingNewAdminUser();
        }
      });
    });
  }

  private initEditExistingAdminUser(adminUser: User) {
    this.formData = {
      id: adminUser.id,
      name: adminUser.name,
      email: adminUser.email,
      password: "",
      role: Role.ADMIN_USER
    };
  }

  private initCreatingNewAdminUser() {
    this.formData = {
      name: "",
      email: "",
      password: "",
      role: Role.ADMIN_USER
    }
  }

  private validateData() {
    if(!this.formData.name) {
      return false;
    }
    if(!this.formData.email) {
      return false;
    }
    if(!this.formData.id && !this.formData.password) {
      return false;
    }
    return true;
  }
  validateEmail() {
    this.isValidEmail = this.validateService.validateEmail(this.formData.email);
    return this.isValidEmail;
  }

  validatePassword() {
    this.isValidPassword = !this.formData.password || this.validateService.validatePassword(this.formData.password);
    return this.isValidPassword;
  }

  save() {
    this.show_errors = true;
    if(!this.validateData() || !this.validateEmail() || !this.validatePassword()) {
      return;
    }
    this.status = 'saving';
    this.userService.editAdminUser(this.formData).subscribe(res => {
      this.status = 'saved';
      this.notifierService.notify('success', "Admin User Saved");
      setTimeout(() => {
        this.router.navigate(['/admin-users'])
      }, 1000)
    }, error => {
      this.status = null;
      this.notifierService.notify('error', error.error);
    });
  }

}
