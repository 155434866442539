import { Component, OnInit } from "@angular/core";
import { StatRequestData, StatService } from "../../../services/stat.service";
import { UserService } from "../../../services/user.service";
import { User } from "../../../models/user";
import { ProductService } from "../../../services/product.service";
import { Product } from "../../../models/product";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { Role } from "../../../constants";
import _date = moment.unitOfTime._date;
import { Util } from "src/app/util";

declare var $;

@Component({
  selector: "app-analytics",
  templateUrl: "./analytics.component.html",
  styleUrls: ["./analytics.component.css"],
})
export class AnalyticsComponent implements OnInit {
  user: User;
  currentConfiguringOption = 0;
  currentConfiguringOptionStartDate: Date;
  currentConfiguringOptionEndDate: Date;
  productRevenuesData: StatRequestData;
  productRevenues: number;
  productRevenuesIncrement: number;
  productReservesData: StatRequestData;
  productReserves: number;
  productReservesIncrement: number;
  newBrandUsersData: StatRequestData;
  newBrandUsers: number;
  newBrandUsersIncrement: number;
  newWreamsData: StatRequestData;
  newWreams: number;
  newWreamsIncrement: number;
  newVisitorsData: StatRequestData;
  newVisitors: number;
  newVisitorsIncrement: number;
  products: Product[];
  wreamVisitors: {} = {};
  wreamRatings: {} = {};
  limitedProducts: Product[] = [];
  limit = 10;
  topWreams: any;

  loadingNewBrandUser: boolean;
  loadingNewWreams: boolean;
  loadingNewVisitors: boolean;
  loadingProductReserves: boolean;
  loadingProductRevenues: boolean;

  constructor(
    private statService: StatService,
    private userService: UserService,
    private productService: ProductService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.colChange();
  }

  async ngOnInit() {
    await this.userService.getUser().subscribe((user) => {
      this.user = user;

      if (this.user.role === Role.ADMIN_USER) {
        this.router.navigateByUrl("/brands");
        return;
      }

      if (this.user.role === Role.SUPER_ADMIN || this.user.role == Role.BRAND_ADMIN) {
        this.route.params.subscribe((params) => {
          const brandId = Util.getBrandIdBasedOnRole(user, params);
          if (brandId) {
            this.initStatRequestData(brandId);
            this.getNewVisitors();
            this.getProductWreamCount();
            this.getBrandNewVisitors();
            this.getProductWreamRevenues();
            this.getProductWreamReservesCount();
            this.getRecentProducts();
          }
          this.getTopWreams();
        });
      }
    });
  }

  getTopWreams() {
    this.statService.getTopWreams().subscribe((topWreams) => {
      this.topWreams = topWreams;
    });
  }

  initStatRequestData(brandId) {
    const now = new Date();
    const data: StatRequestData = {
      id: brandId,
      type: "brand",
      start: this.subNDays(now, 7),
      end: now,
    };
    this.productRevenuesData = Object.assign({}, data);
    this.productReservesData = Object.assign({}, data);
    this.newBrandUsersData = Object.assign({}, data);
    this.newWreamsData = Object.assign({}, data);
    this.newVisitorsData = Object.assign({}, data);
  }

  getProductWreamRevenues() {
    this.loadingProductRevenues = true;
    const d0 = this.productRevenuesData;
    const d = Object.assign({}, d0);
    d.start = this.subSameDays(d0.start, d0.end);
    d.end = this.subNDays(d0.start, 7);
    this.statService
      .getProductWreamRevenues(d0)
      .subscribe((productRevenues) => {
        this.productRevenues = productRevenues;
        this.statService
          .getProductWreamRevenues(d)
          .subscribe((productRevenues14Days) => {
            this.productRevenuesIncrement = this.validatePercentages(
              productRevenues - productRevenues14Days,
              productRevenues14Days
            );
            this.loadingProductRevenues = false;
          });
      });
  }

  getProductWreamCount() {
    this.loadingNewWreams = true;
    const d0 = this.newWreamsData;
    const d = Object.assign({}, d0);
    d.start = this.subSameDays(d0.start, d0.end);
    d.end = this.subNDays(d0.start, 7);
    this.statService.getProductWreamCount(d0).subscribe((wreamCount) => {
      this.newWreams = wreamCount;
      this.statService.getProductWreamCount(d).subscribe((wreamCount14Days) => {
        this.newWreamsIncrement = this.validatePercentages(
          wreamCount - wreamCount14Days,
          wreamCount14Days
        );
        this.loadingNewWreams = false;
      });
    });
  }

  getProductWreamReservesCount() {
    this.loadingProductReserves = true;
    const d0 = this.productReservesData;
    const d = Object.assign({}, d0);
    d.start = this.subSameDays(d0.start, d0.end);
    d.end = this.subNDays(d0.start, 7);
    this.statService
      .getProductWreamReservesCount(d0)
      .subscribe((reservesCount) => {
        this.productReserves = reservesCount;
        this.statService
          .getProductWreamReservesCount(d)
          .subscribe((reservesCount14Days) => {
            this.productReservesIncrement = this.validatePercentages(
              reservesCount - reservesCount14Days,
              reservesCount14Days
            );
            this.loadingProductReserves = false;
          });
      });
  }

  getNewVisitors() {
    this.loadingNewBrandUser = true;
    const d0 = this.newBrandUsersData;
    const d = Object.assign({}, d0);
    d.start = this.subSameDays(d0.start, d0.end);
    d.end = this.subNDays(d0.start, 7);
    this.statService.getNewVisitors(d0).subscribe((newBrandUsers) => {
      this.newBrandUsers = newBrandUsers;
      this.statService.getNewVisitors(d).subscribe((newBrandUsers14Days) => {
        this.newBrandUsersIncrement = this.validatePercentages(
          newBrandUsers - newBrandUsers14Days,
          newBrandUsers14Days
        );
        this.loadingNewBrandUser = false;
      });
    });
  }

  getBrandNewVisitors() {
    this.loadingNewVisitors = true;
    const d0 = this.newVisitorsData;
    const d = Object.assign({}, d0);
    d.start = this.subSameDays(d0.start, d0.end);
    d.end = this.subNDays(d0.start, 7);
    this.statService.getBrandNewVisitors(d0).subscribe((visitors) => {
      this.newVisitors = visitors;
      this.statService.getBrandNewVisitors(d).subscribe((visitors14Days) => {
        this.newVisitorsIncrement = this.validatePercentages(
          visitors - visitors14Days,
          visitors14Days
        );
        this.loadingNewVisitors = false;
      });
    });
  }

  validatePercentages(numerator: number, denominator: number) {
    if ((numerator === 0 && denominator === 0) || numerator === 0) {
      return 0;
    } else if (denominator === 0) {
      return 0;
    } else {
      return Math.round((numerator / denominator) * 100 * 10) / 10;
    }
  }

  getRecentProducts() {
    this.productService.getRecentProducts().subscribe((products) => {
      this.products = products;
      this.limitProducts();

      for (let wream of this.products) {
        const now = new Date();
        const data: StatRequestData = {
          id: wream.id,
          type: "product",
          start: this.subNDays(now, 365),
          end: now,
        };
        this.statService.getNewVisitors(data).subscribe((count) => {
          this.wreamVisitors[wream.id] = count;
        });
      }

      for (let wream of this.products) {
        let rateValue = 0;
        if (wream.ratings && wream.ratings.length) {
          for (let rating of wream.ratings) {
            rateValue += Number(rating.rating);
          }
          this.wreamRatings[wream.id] =
            Math.round((rateValue / wream.ratings.length) * 2) / 2;
        } else {
          this.wreamRatings[wream.id] = 0;
        }
      }
      setTimeout(() => {
        this.colChange();
      }, 500);
    });
  }

  limitProducts() {
    this.limitedProducts = this.products.slice(0, Number(this.limit));
  }

  private subNDays(date: Date, sub: number): Date {
    return moment(date).subtract(sub, "days").toDate();
  }

  private subSameDays(start: Date, end: Date): Date {
    const mEnd = moment(end);
    const mStart = moment(start);
    const diff = mEnd.diff(mStart, "days");
    return mStart.subtract(diff, "days").toDate();
  }

  fancyDates1(data: StatRequestData): string {
    let start;
    let end;
    if (data) {
      start = moment(data.start);
      end = moment(data.end);
    }
    if (!data || !start || !end) {
      return "";
    }

    if (0 == moment().diff(end, "days")) {
      let diff = moment().diff(start, "days");
      if (diff <= 30) {
        return `last ${diff} days`;
      } else {
        return this.fancyDates2(data);
      }
    } else {
      return this.fancyDates2(data);
    }
  }

  fancyDates2(data: StatRequestData): string {
    if (!data || !data.start || !data.end) {
      return "";
    }
    const start = moment(data.start);
    const end = moment(data.end);
    if (start.year() == moment().year()) {
      return `${start.format("D MMM")} - ${end.format("D MMM")}`;
    } else {
      return `${start.format("YYYY MMM")} - ${end.format("YYYY MMM")}`;
    }
  }

  configureStatOption(index: number, data: StatRequestData) {
    this.currentConfiguringOption = index;
    this.currentConfiguringOptionStartDate = data.start;
    this.currentConfiguringOptionEndDate = data.end;
  }

  updateStats() {
    switch (this.currentConfiguringOption) {
      case 1: {
        this.newBrandUsersData.start = this.currentConfiguringOptionStartDate;
        this.newBrandUsersData.end = this.currentConfiguringOptionEndDate;
        this.getNewVisitors();
        break;
      }
      case 2: {
        this.newWreamsData.start = this.currentConfiguringOptionStartDate;
        this.newWreamsData.end = this.currentConfiguringOptionEndDate;
        this.getProductWreamCount();
        break;
      }
      case 3: {
        this.newVisitorsData.start = this.currentConfiguringOptionStartDate;
        this.newVisitorsData.end = this.currentConfiguringOptionEndDate;
        this.getBrandNewVisitors();
        break;
      }
      case 4: {
        this.productReservesData.start = this.currentConfiguringOptionStartDate;
        this.productReservesData.end = this.currentConfiguringOptionEndDate;
        this.getProductWreamReservesCount();
        break;
      }
      case 5: {
        this.productRevenuesData.start = this.currentConfiguringOptionStartDate;
        this.productRevenuesData.end = this.currentConfiguringOptionEndDate;
        this.getProductWreamRevenues();
        break;
      }
      default: {
        break;
      }
    }
    this.currentConfiguringOption = 0;
  }

  colChange() {
    (function ($) {
      var $window = $(window),
        $html = $("html");

      $window
        .resize(function resize() {
          if ($window.width() > 1629) {
            $(".col-change").addClass("col-lg-3");
            $(".col-change").removeClass("col-lg-4");
          } else {
            $(".col-change").removeClass("col-lg-3");
            $(".col-change").addClass("col-lg-4");
          }

          $html.removeClass("mobile");
        })
        .trigger("resize");
    })($);
  }

  closePopup() {
    this.currentConfiguringOption = 0;
  }
}
