import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {UserService} from "../../../services/user.service";
import {NotifierService} from "angular-notifier";
import {EditOfferRequest, OfferService} from "../../../services/offer.service";
import {Offer} from "../../../models/offer";
import {ProductService} from "../../../services/product.service";
import {Product} from "../../../models/product";
import {Util} from "../../../util";

@Component({
  selector: 'app-edit-offer',
  templateUrl: './edit-offer.component.html',
  styleUrls: ['./edit-offer.component.css']
})
export class EditOfferComponent implements OnInit {

  show_errors = false;
  status: string = null;
  formData: EditOfferRequest;
  productPrice: number;
  minDate: Date = new Date();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private offerService: OfferService,
    private productService: ProductService,
    private notifierService: NotifierService
  ) { }

  ngOnInit() {
    this.userService.getUser().subscribe(user => {
      this.route.params.subscribe((params) => {
        if(params.offer_id) {
          this.offerService.getOfferById(params.offer_id).subscribe(offer => {
            this.productService.getProductById(offer.product_id).subscribe(product => {
              this.initEditExistingOffer(product, offer);
            });
          });
        } else if(params.product_id) {
          this.productService.getProductById(params.product_id).subscribe(product => {
            this.initCreatingNewOffer(product);
          });
        }
      });
    });
  }

  private initEditExistingOffer(product: Product, offer: Offer) {
    this.formData = {
      product_id: product.id,
      title: offer.title,
      description: offer.description,
      photo: offer.photo,
      offer_percentage: offer.offer_percentage,
      start_date: offer.start_date,
      end_date: offer.end_date
    };
    if(0 != this.router.url.indexOf("/duplicate-offer")) {
      this.formData.id = offer.id;
    }
    this.productPrice = product.price;
  }

  private initCreatingNewOffer(product: Product) {
    this.formData = {
      product_id: product.id,
      title: "",
      description: "",
      photo: "",
      offer_percentage: null,
      start_date: null,
      end_date: null
    };
    this.productPrice = product.price;
  }

  private validateData() {
    if(!this.formData.title) {
      return false;
    }
    if(!this.formData.description) {
      return false;
    }
    if(!this.formData.id && !this.formData.photo) {
      return false;
    }
    if(!this.formData.offer_percentage) {
      return false;
    }
    if(!this.formData.start_date) {
      return false;
    }
    if(!this.formData.end_date) {
      return false;
    }
    return true;
  }

  // handleFileInput(files: FileList) {
  //   const file = files.item(0);
  //   if(file) {
  //     this.offerService.uploadOfferImage(file).subscribe(url => {
  //       this.formData.photo = url.url;
  //     });
  //   }
  // }

  handleFileInput(files: FileList) {

    var file = files[0];

    if (files && file) {
        var reader = new FileReader();

        reader.onload = this._handleReaderLoaded.bind(this);

        reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
      var base64textString= btoa(binaryString);

      this.offerService.uploadOfferImage(base64textString).subscribe(url => {
      this.formData.photo = url.url;
    });
   }

  save() {
    this.show_errors = true;
    if(!this.validateData()) {
      return;
    }
    this.status = 'saving';
    this.offerService.editOffer(this.formData).subscribe(offer => {
      this.status = 'saved';
      this.show_errors = false;
      this.notifierService.notify('success', "Offer Saved");
      setTimeout(() => {
        this.router.navigate(['/offer/' + offer.id])
      }, 1000)
    }, error => {
      this.status = null;
      this.notifierService.notify('error', error.statusText);
    });
  }

  getOfferPercentage() {
    return this.productPrice - (0 < this.formData.offer_percentage ? this.productPrice * this.formData.offer_percentage / 100 : 0);
  }

  fileName(url) {
    return Util.getFileName(url)
  }
}
