import {Component, OnInit} from '@angular/core';
import {Role, VerifyStatus} from "../../../constants";
import {User} from "../../../models/user";
import {Product} from "../../../models/product";
import {UserService} from "../../../services/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ProductService} from "../../../services/product.service";
import {NotifierService} from "angular-notifier";
import {StatRequestData, StatService} from "../../../services/stat.service";
import * as moment from 'moment';
import swal from 'sweetalert';
@Component({
  selector: 'app-view-product',
  templateUrl: './view-product.component.html',
  styleUrls: ['./view-product.component.css']
})
export class ViewProductComponent implements OnInit {

  product: Product;
  canModify = false;
  canVerify = false;
  approving = false;
  rejecting = false;
  verifyStatus = VerifyStatus;
  user;
  productId: any;
  wreamRatings: number = 0;
  wreamViews: number = 0;
  productRevenuesData: StatRequestData;

  canRemoveProduct: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private productService: ProductService,
    private userService: UserService,
    private notifierService: NotifierService,
    private statService: StatService
  ) {
  }

  ngOnInit() {
    this.userService.getUser().subscribe((user: User) => {
      this.user = user;
      this.canModify = user.role == Role.BRAND_ADMIN || user.role == Role.SUPER_ADMIN || user.role == Role.ADMIN_USER;
      this.canRemoveProduct = user.role == Role.SUPER_ADMIN || user.role == Role.BRAND_ADMIN;
      this.canVerify = user.role == Role.SUPER_ADMIN
    });
    this.route.params.subscribe(params => {
      this.productId = params.product_id;
      if (params.product_id) {
        this.getProduct(params.product_id);
      }
    });
  }

  private getProduct(productId) {
    this.productService.getProductById(productId).subscribe((product: Product) => {
      this.product = product;

      let rateValue = 0;
      if (this.product.ratings && this.product.ratings.length) {
        for (let rating of this.product.ratings) {
          rateValue += Number(rating.rating);
        }
        this.wreamRatings = Math.round((rateValue / this.product.ratings.length) * 2) / 2;
      }
      const now = new Date();
      const data: StatRequestData = {
        id: this.product.id,
        type: "product",
        start: this.subNDays(now, 365),
        end: now
      };
      this.statService.getNewVisitors(data).subscribe(count => {
        this.wreamViews = count;
      });
    });
  }

  approveProduct() {
    this.approving = true;
    this.productService.changeStatus({'id': this.product.id, 'status': VerifyStatus.APPROVED}).subscribe(() => {
      this.approving = false;
      this.notifierService.notify('success', "Product Approved");
      this.getProduct(this.product.id);
    }, () => {
      this.approving = false;
    });
  }


  rejectProduct() {
    this.rejecting = true;
    this.productService.changeStatus({'id': this.product.id, 'status': VerifyStatus.REJECTED}).subscribe(() => {
      this.rejecting = false;
      this.notifierService.notify('success', "Product Rejected");
      this.getProduct(this.product.id);
    }, () => {
      this.rejecting = false;
    });
  }


  removeProduct() {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this file!",
      icon: "warning",
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        this.productService.deleteProductById(this.product.id).subscribe((res) => {
          if(res.success) {
            this.productService.currentListedBrand = null;
            this.productService.currentListedProducts = [];

            swal("Your file has been deleted!", {
              icon: "success",
            });

            this.router.navigateByUrl("/products/" + this.product.brand_id);
          } else {
            swal(res.message);
          }
        })
      }
    });
  }

  productUrl() {
    if(this.product.url){

      const p1 = "http://";
      const p2 = "https://";
      // const targetUrl: string = -1 < this.product.url.lastIndexOf(p1) || this.product.url.lastIndexOf(p2) ? this.product.url : p1 + this.product.url;
      const targetUrl = `${this.product.url}`

      return targetUrl;



      // return -1 < this.product.url.lastIndexOf(p1) || this.product.url.lastIndexOf(p2) ? this.product.url : p1 + this.product.url;
    }
  }

  private subNDays(date: Date, sub: number): Date {
    return moment(date).subtract(sub, 'days').toDate();
  }
}
