import { Component, OnInit } from '@angular/core';
import {UserService} from "../../../services/user.service";
import {BannerService} from "../../../services/banner.service";
import {Role, VerifyStatus} from "../../../constants";
import {Banner} from "../../../models/banner";
import {NotifierService} from "angular-notifier";

enum SortColumn {
  BANNER_NAME,
  BANNER_DESCRIPTION,
  BANNER_URL,
  BRAND_NAME,
  BANNER_TYPE,
  START_DATE,
  END_DATE
}

@Component({
  selector: 'app-pending-banners',
  templateUrl: './pending-banners.component.html',
  styleUrls: ['./pending-banners.component.css']
})

export class PendingBannersComponent implements OnInit {

  sortColumns = SortColumn;
  sortColumn: SortColumn;
  sortAscending = true;
  approvingId = null;
  rejectingId = null;
  pendingBanners: Banner[];
  collection: {count:number, data:Banner[]} = {count: 0, data: []};
  config = {
    id: 'pagination1',
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: this.collection.count
  };

  constructor(
    private userService: UserService,
    private bannerService: BannerService,
    private notifierService: NotifierService
  ) { }

  ngOnInit() {
    this.userService.getUser().subscribe(user => {
      if(user.role === Role.SUPER_ADMIN) {
        this.getPendingBanners();
      }
    });
  }

  getPendingBanners() {
    this.bannerService.getPendingBanners().subscribe(banners => {
      this.pendingBanners = banners;
      this.sortBanners(SortColumn.START_DATE);
      this.collection = {count: banners.length, data: this.pendingBanners}
    })
  }

  approveBanner(banner_id: number) {
    this.approvingId = banner_id;
    this.bannerService.changeStatus({'id': banner_id, 'status': VerifyStatus.APPROVED}).subscribe(() => {
      this.approvingId = null;
      this.getPendingBanners();
      this.notifierService.notify('success', "Banner Approved");
    }, () => {
      this.approvingId = null;
    });
  }

  rejectBanner(banner_id: number) {
    this.rejectingId = banner_id;
    this.bannerService.changeStatus({'id': banner_id, 'status': VerifyStatus.REJECTED}).subscribe(() => {
      this.rejectingId = null;
      this.getPendingBanners();
      this.notifierService.notify('success', "Banner Rejected");
    }, () => {
      this.rejectingId = null;
    });
  }

  sortBanners(sortingType) {
    if(this.sortColumn == sortingType) {
      this.sortAscending = !this.sortAscending;
    }
    this.sortColumn = sortingType;
    function getAttribute(p: Banner) {
      switch (type) {
        case SortColumn.BANNER_NAME:
          return p.title;
        case SortColumn.BRAND_NAME:
          return p.brand.name;
        case SortColumn.BANNER_TYPE:
          return p.type;
        case SortColumn.START_DATE:
          return (new Date(p.start_date).getTime());
        case SortColumn.END_DATE:
          return (new Date(p.end_date).getTime());
      }
    }
    function sort(a, b, ascending) {
      const m = ascending ? 1 : -1;
      const attrA = getAttribute(a);
      const attrB = getAttribute(b);
      return m*(attrA < attrB ? 1 : (attrA > attrB ? -1 : 0))
    }

    const type = parseInt(sortingType);
    this.pendingBanners.sort((a, b) => sort(a, b, this.sortAscending));
  }
}
