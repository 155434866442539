import { Component, OnInit } from '@angular/core';
import {User} from "../../../models/user";
import {UserService} from "../../../services/user.service";
import {OfferService} from "../../../services/offer.service";
import {Offer} from "../../../models/offer";
import {ActivatedRoute, Router} from "@angular/router";
import {Role} from "../../../constants";
import {Util} from "../../../util";

@Component({
  selector: 'app-all-offers',
  templateUrl: './all-offers.component.html',
  styleUrls: ['./all-offers.component.css']
})
export class AllOffersComponent implements OnInit {

  filteringProductId = null;
  filteringStatus = "ANY";
  filteringBrandId = null;
  offers: Offer[] = [];
  filteredOffers: Offer[] = [];
  displayedOffers: Offer[] = [];
  displayedPageCount = 1;
  itemsPerPage = 6;
  loading: boolean;
  canModify: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private offerService: OfferService,
    private userService: UserService) { }

  ngOnInit() {
    this.loading = true;
    this.userService.getUser().subscribe((user: User) => {
      this.canModify = user.role == Role.BRAND_ADMIN || user.role == Role.SUPER_ADMIN || user.role == Role.ADMIN_USER
      this.route.params.subscribe( params => {
        if(params.product_id) {
          this.filteringProductId = params.product_id;
        }
        if(params.brand_id) {
          this.filteringBrandId = params.brand_id;
        }
        if(user.role === Role.BRAND_ADMIN || user.role === Role.BRAND_USER) {
          this.filteringBrandId = Util.getBrandIdBasedOnRole(user, params);
          this.offerService.getOffersForBrand(this.filteringBrandId).subscribe((offers: Offer[]) => {
            this.offers = offers;
            this.filterOffers();
            this.loading = false;
          });
        } else {
          this.offerService.getAllOffers().subscribe((offers: Offer[]) => {
            this.offers = offers;
            this.filterOffers();
            this.loading = false;
          });
        }
      });
    });
  }

  filterOffers() {
    this.filteredOffers = this.offers.filter(offer => {
      let status = true;
      status = status && (!this.filteringProductId || this.filteringProductId == offer.product_id);
      status = status && (!this.filteringBrandId || this.filteringBrandId == offer.product.brand_id);
      status = status && (this.filteringStatus == "ANY" || this.filteringStatus == offer.status);
      return status;
    });
    this.displayedPageCount = 1;
    this.updateDisplayOffers();
  }

  private updateDisplayOffers() {
    this.displayedOffers = this.filteredOffers.slice(0, this.displayedPageCount * this.itemsPerPage)
  }

  loadMore() {
    this.displayedPageCount += 1;
    this.updateDisplayOffers();
  }

  hasMore() {
    return this.displayedPageCount * this.itemsPerPage < this.filteredOffers.length;
  }

}
