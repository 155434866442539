import { Injectable } from "@angular/core";
import { Banner } from "../models/banner";
import { Observable } from "rxjs/internal/Observable";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../config";
import { ChangeStatusRequest } from "../models/request";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class BannerService {
  constructor(private httpClient: HttpClient) {}

  public getBannerById(bannerId: number): Observable<Banner | null> {
    const ep = AppConfig.apiUrl("brand/banner/" + bannerId);
    return this.httpClient.get<any>(ep).pipe(map((response) => response.data));
  }

  public getBannersForBrand(brandId: number): Observable<Banner[]> {
    const ep = AppConfig.apiUrl("brand/" + brandId + "/banners");
    return this.httpClient.get<any>(ep).pipe(map((response) => response.data));
  }

  public getAllBanners(): Observable<Banner[]> {
    const ep = AppConfig.apiUrl("banners/all");
    return this.httpClient.get<any>(ep).pipe(map((response) => response.data));
  }

  public editBanner(data: EditBannerRequest): Observable<Banner> {
    const ep = AppConfig.apiUrl("brand/banner");
    return this.httpClient
      .post<any>(ep, data)
      .pipe(map((response) => response.data));
  }

  public deleteBannerById(bannerId: any): Observable<any> {
    const ep = AppConfig.apiUrl("banner/delete/" + bannerId);
    return this.httpClient.delete<any>(ep).pipe(
      map((response) => response));
  }

  public uploadBannerImage(fileToUpload: any): Observable<any> {
    const ep = AppConfig.apiUrl("upload/banner");
    const formData: FormData = new FormData();
    formData.append("file", fileToUpload);
    return this.httpClient
      .post<any>(ep, formData)
      .pipe(map((response) => response.data));
  }

  public getPendingBanners(): Observable<Banner[]> {
    const ep = AppConfig.apiUrl("pending/banners");
    return this.httpClient.get<any>(ep).pipe(map((response) => response.data));
  }

  public changeStatus(data: ChangeStatusRequest): Observable<void> {
    const ep = AppConfig.apiUrl("banner/verify");
    return this.httpClient
      .post<any>(ep, data)
      .pipe(map((response) => response.data));
  }
}

export interface EditBannerRequest {
  id?: number;
  brand_id: number;
  title: string;
  description: string;
  url: string;
  status: string;
  type: string;
  photo: string;
  start_date: Date;
  end_date: Date;
}
