import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import {Role} from '../../../constants'
import { BrandService } from 'src/app/services/brand.service';
import { ActivatedRoute } from '@angular/router';
import {Util} from "../../../util";
declare var $
@Component({
  selector: 'app-all-brand-users',
  templateUrl: './all-brand-users.component.html',
  styleUrls: ['./all-brand-users.component.css']
})
export class AllBrandUsersComponent implements OnInit {
  canEdit = false;
  brands
  brandId
  users
  user
  config: any;
  collection = {count: 0, data: []};
  constructor(private userService: UserService,private route: ActivatedRoute, private brandService: BrandService) {
    this.config = {
      itemsPerPage: 12,
      currentPage: 1,
      totalItems: this.collection.count
    };
   }

  async ngOnInit() {
    this.userService.getUser().subscribe(user => {
      this.user = user;
      this.canEdit = user.role == Role.BRAND_ADMIN || user.role == Role.SUPER_ADMIN || user.role == Role.ADMIN_USER;
      this.route.params.subscribe(params => {
        this.brandId = Util.getBrandIdBasedOnRole(user, params);
        this.userService.getBrandUsers(this.brandId).subscribe(users=>{
          this.users = users;
          this.collection = {count: users.length, data: users};
          $('.loader').hide()
        });
      });
    });
  }

  filterByBrand(){
    var barndId = $('#brandId').val()
    if(barndId != ""){
     let users = this.users.filter(user=> user.brand != null && user.brand.id == barndId)
     this.collection = {count: users.length, data: users}
    }else{
      this.collection = {count: this.users.length, data: this.users}
    }
  }
}
