import { Injectable } from '@angular/core';
import {Offer} from "../models/offer";
import {Observable} from "rxjs/internal/Observable";
import {HttpClient} from "@angular/common/http";
import {AppConfig} from "../config";
import {ChangeStatusRequest} from "../models/request";
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OfferService {

  constructor(private httpClient: HttpClient) { }

  public getOfferById(offerId: number): Observable<Offer | null> {
    const ep = AppConfig.apiUrl('brand/offer/' + offerId);
    return this.httpClient.get<any>(ep).pipe(map(response => response.data));
  }

  public getOffersForBrand(brandId: number): Observable<Offer[]> {
    const ep = AppConfig.apiUrl('brand/' + brandId + '/offers');
    return this.httpClient.get<any>(ep).pipe(map(response => response.data));
  }

  public deleteOfferById(offerId: any): Observable<any> {
    const ep = AppConfig.apiUrl("offer/delete/" + offerId);
    return this.httpClient.delete<any>(ep).pipe(
      map((response) => response));
  }

  public getAllOffers(): Observable<Offer[]> {
    const ep = AppConfig.apiUrl('offers/all');
    return this.httpClient.get<any>(ep).pipe(map(response => response.data));
  }

  public editOffer(data: EditOfferRequest): Observable<Offer> {
    const ep = AppConfig.apiUrl('brand/offer');
    return this.httpClient.post<any>(ep, data).pipe(map(response => response.data));
  }

  public uploadOfferImage(fileToUpload: any): Observable<any> {
    const ep = AppConfig.apiUrl('upload/offer');
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload);
    return this.httpClient.post<any>(ep, formData).pipe(map(response => response.data));
  }

  public getPendingOffers(): Observable<Offer[]> {
    const ep = AppConfig.apiUrl('offers/pending');
    return this.httpClient.get<any>(ep).pipe(map(response => response.data));
  }

  public changeStatus(data: ChangeStatusRequest): Observable<void> {
    const ep = AppConfig.apiUrl('offer/verify');
    return this.httpClient.post<any>(ep, data).pipe(map(response => response.data));
  }

}

export interface EditOfferRequest {
  id?: number,
  product_id: number,
  title: string,
  description: string,
  photo: string,
  offer_percentage: number,
  start_date: Date,
  end_date: Date
}
