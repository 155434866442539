import {Injectable} from '@angular/core';
import {Product} from "../models/product";
import {Observable} from "rxjs/internal/Observable";
import {HttpClient} from "@angular/common/http";
import {AppConfig} from "../config";
import {Photo} from "../models/photo";
import {ChangeStatusRequest} from "../models/request";
import {map} from 'rxjs/operators';
import { Brand } from '../models/brand';

@Injectable({
  providedIn: 'root'
})

export class ProductService {
  constructor(private httpClient: HttpClient) {
  }

  public currentListedProducts: Product[];
  public currentListedBrand: number;

  public getProductById(productId: number): Observable<Product | null> {
    const ep = AppConfig.apiUrl('brand/product/' + productId);
    return this.httpClient.get<any>(ep).pipe(map(response => response.data));
  }

  public deleteProductById(productId: any): Observable<any> {
    const ep = AppConfig.apiUrl("product/delete/" + productId);
    return this.httpClient.delete<any>(ep).pipe(
      map((response) => response));
  }

  public getProductsForBrand(brandId: number): Observable<Product[]> {
    const ep = AppConfig.apiUrl('brand/' + brandId + '/products');
    return this.httpClient.get<any>(ep).pipe(map(response => response.data));
  }

  public getRecentProducts(): Observable<Product[]> {
    const ep = AppConfig.apiUrl('recent-products');
    return this.httpClient.get<any>(ep).pipe(map(response => response.data));
  }

  public getAllProducts(): Observable<Product[]> {
    const ep = AppConfig.apiUrl('products/all');
    return this.httpClient.get<any>(ep).pipe(map(response => response.data));
  }

  public getPendingProducts(): Observable<Product[]> {
    const ep = AppConfig.apiUrl('products/pending');
    return this.httpClient.get<any>(ep).pipe(map(response => response.data));
  }

  public editProduct(data: EditProductRequest): Observable<any> {
    const ep = AppConfig.apiUrl('brand/product');
    return this.httpClient.post<any>(ep, data).pipe(map(response => response));
  }

  public changeStatus(data: ChangeStatusRequest): Observable<void> {
    const ep = AppConfig.apiUrl('product/verify');
    return this.httpClient.post<any>(ep, data).pipe(map(response => response.data));
  }

  public uploadProductImage(fileToUpload: any): Observable<any> {
    const ep = AppConfig.apiUrl('upload/product');
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload);
    return this.httpClient.post<any>(ep, formData).pipe(map(response => response.data));
  }

  public getWreamPhotoByUrl(file: any): Observable<any> {
    const ep = AppConfig.apiUrl('getWreamPhotoByUrl');
    const formData: FormData = new FormData();
    formData.append('url', file);
    return this.httpClient.post<any>(ep, formData).pipe(map(response => response.data));
  }

}

export interface EditProductRequest {
  id?: number,
  brand_id: number,
  category_id: number,
  title: string,
  description: string,
  currency: string,
  price: number,
  photos: Photo[]
  url: string
}
