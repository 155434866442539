import { Component, OnInit } from "@angular/core";
import { OverallStatRequestData, StatRequestData, StatService } from "../../../services/stat.service";
import { UserService } from "../../../services/user.service";
import { User } from "../../../models/user";
import { ProductService } from "../../../services/product.service";
import { Product } from "../../../models/product";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { Role } from "../../../constants";
import _date = moment.unitOfTime._date;

declare var $;

@Component({
  selector: "app-overall-analytics",
  templateUrl: "./overall-analytics.component.html",
  styleUrls: ["./overall-analytics.component.css"],
})
export class OverallAnalyticsComponent implements OnInit {
  user: User;
  currentConfiguringOption = 0;
  currentConfiguringOptionStartDate: Date;
  currentConfiguringOptionEndDate: Date;
  products: Product[];
  wreamVisitors: {} = {};
  wreamRatings: {} = {};
  limitedProducts: Product[] = [];
  limit = 10;
  topWreams: any;

  loadingNewBrandUser: boolean;
  loadingNewWreams: boolean;
  loadingNewVisitors: boolean;
  loadingProductReserves: boolean;
  loadingProductRevenues: boolean;

  allProductRevenues: number;
  allNewWreams: number;
  allProductReserves: number;
  allNewVisitors: number;
  allNewBrandUsers: number;

  allProductRevenuesData: OverallStatRequestData;
  allNewWreamsData: OverallStatRequestData;
  allProductReservesData: OverallStatRequestData;
  allNewVisitorsData: OverallStatRequestData;
  allNewBrandUsersData: OverallStatRequestData;

  allProductRevenuesIncrement: number;
  allNewWreamsIncrement: number;
  allProductReservesIncrement: number;
  allNewVisitorsIncrement: number;
  allNewBrandUsersIncrement: number;

  constructor(
    private statService: StatService,
    private userService: UserService,
    private productService: ProductService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.colChange();
  }

  async ngOnInit() {
    await this.userService.getUser().subscribe((user) => {
      this.user = user;

      if (this.user.role === Role.ADMIN_USER) {
        this.router.navigateByUrl("/brands");
        return;
      }

      if (this.user.role === Role.SUPER_ADMIN) {
        this.initAllStatRequestData();
        this.getAllProductWreamRevenues();
        this.getAllProductWreamCount();
        this.getAllProductWreamReservesCount();
        this.getAllBrandNewVisitors();
        // this.getRecentProducts();
        this.getAllNewBrandusers();
      }
    });
  }

  getTopWreams() {
    this.statService.getTopWreams().subscribe((topWreams) => {
      this.topWreams = topWreams;
    });
  }


  initAllStatRequestData() {
    const now = new Date();
    const data: OverallStatRequestData = {
      type: "brand",
      start: this.subNDays(now, 7),
      end: now,
    };
    this.allProductRevenuesData = Object.assign({}, data);
    this.allProductReservesData = Object.assign({}, data);
    this.allNewBrandUsersData = Object.assign({}, data);
    this.allNewWreamsData = Object.assign({}, data);
    this.allNewVisitorsData = Object.assign({}, data);
  }

  getAllProductWreamRevenues() {
    this.loadingProductRevenues = true;
    const d0 = this.allProductRevenuesData;
    const d = Object.assign({}, d0);
    d.start = this.subSameDays(d0.start, d0.end);
    d.end = this.subNDays(d0.start, 7);
    this.statService
      .getAllProductWreamRevenues(d0)
      .subscribe((productRevenues) => {
        this.allProductRevenues = productRevenues;
        this.statService
          .getAllProductWreamRevenues(d)
          .subscribe((productRevenues14Days) => {
            this.allProductRevenuesIncrement = this.validatePercentages(
              productRevenues - productRevenues14Days,
              productRevenues14Days
            );
            this.loadingProductRevenues = false;
          });
      });
  }

  getAllProductWreamCount() {
    this.loadingNewWreams = true;
    const d0 = this.allNewWreamsData;
    const d = Object.assign({}, d0);
    d.start = this.subSameDays(d0.start, d0.end);
    d.end = this.subNDays(d0.start, 7);
    this.statService.getAllProductWreamCount(d0).subscribe((wreamCount) => {
      this.allNewWreams = wreamCount;
      this.statService.getAllProductWreamCount(d).subscribe((wreamCount14Days) => {
        this.allNewWreamsIncrement = this.validatePercentages(
          wreamCount - wreamCount14Days,
          wreamCount14Days
        );
        this.loadingNewWreams = false;
      });
    });
  }

  getAllProductWreamReservesCount() {
    this.loadingProductReserves = true;
    const d0 = this.allProductReservesData;
    const d = Object.assign({}, d0);
    d.start = this.subSameDays(d0.start, d0.end);
    d.end = this.subNDays(d0.start, 7);
    this.statService
      .getAllProductWreamReservesCount(d0)
      .subscribe((reservesCount) => {
        this.allProductReserves = reservesCount;
        this.statService
          .getAllProductWreamReservesCount(d)
          .subscribe((reservesCount14Days) => {
            this.allProductReservesIncrement = this.validatePercentages(
              reservesCount - reservesCount14Days,
              reservesCount14Days
            );
            this.loadingProductReserves = false;
          });
      });
  }

  getAllNewBrandusers() {
    this.loadingNewBrandUser = true;
    const d0 = this.allNewBrandUsersData;
    const d = Object.assign({}, d0);
    d.start = this.subSameDays(d0.start, d0.end);
    d.end = this.subNDays(d0.start, 7);
    
    this.statService.getAllNewVisitors(d0).subscribe((newBrandUsers) => {
      this.allNewBrandUsers = newBrandUsers;
      this.statService.getAllNewVisitors(d).subscribe((newBrandUsers14Days) => {
        this.allNewBrandUsersIncrement = this.validatePercentages(
          newBrandUsers - newBrandUsers14Days,
          newBrandUsers14Days
        );
        this.loadingNewBrandUser = false;
      });
    });
  }

  getAllBrandNewVisitors() {
    this.loadingNewVisitors = true;
    const d0 = this.allNewVisitorsData;
    const d = Object.assign({}, d0);
    d.start = this.subSameDays(d0.start, d0.end);
    d.end = this.subNDays(d0.start, 7);

    this.statService.getAllBrandNewVisitors(d0).subscribe((visitors) => {
      this.allNewVisitors = visitors;
      this.statService.getAllBrandNewVisitors(d).subscribe((visitors14Days) => {
        this.allNewVisitorsIncrement = this.validatePercentages(
          visitors - visitors14Days,
          visitors14Days
        );
        this.loadingNewVisitors = false;
      });
    });
  }

  validatePercentages(numerator: number, denominator: number) {
    if ((numerator === 0 && denominator === 0) || numerator === 0) {
      return 0;
    } else if (denominator === 0) {
      return 0;
    } else {
      return Math.round((numerator / denominator) * 100 * 10) / 10;
    }
  }

  getRecentProducts() {
    this.productService.getRecentProducts().subscribe((products) => {
      this.products = products;
      this.limitProducts();

      for (let wream of this.products) {
        const now = new Date();
        const data: StatRequestData = {
          id: wream.id,
          type: "product",
          start: this.subNDays(now, 365),
          end: now,
        };
        this.statService.getNewVisitors(data).subscribe((count) => {
          this.wreamVisitors[wream.id] = count;
        });
      }

      for (let wream of this.products) {
        let rateValue = 0;
        if (wream.ratings && wream.ratings.length) {
          for (let rating of wream.ratings) {
            rateValue += Number(rating.rating);
          }
          this.wreamRatings[wream.id] =
            Math.round((rateValue / wream.ratings.length) * 2) / 2;
        } else {
          this.wreamRatings[wream.id] = 0;
        }
      }
      setTimeout(() => {
        this.colChange();
      }, 500);
    });
  }

  limitProducts() {
    this.limitedProducts = this.products.slice(0, Number(this.limit));
  }

  private subNDays(date: Date, sub: number): Date {
    return moment(date).subtract(sub, "days").toDate();
  }

  private subSameDays(start: Date, end: Date): Date {
    const mEnd = moment(end);
    const mStart = moment(start);
    const diff = mEnd.diff(mStart, "days");
    return mStart.subtract(diff, "days").toDate();
  }

  fancyDates1(data: StatRequestData): string {
    let start;
    let end;
    if (data) {
      start = moment(data.start);
      end = moment(data.end);
    }
    if (!data || !start || !end) {
      return "";
    }

    if (0 == moment().diff(end, "days")) {
      let diff = moment().diff(start, "days");
      if (diff <= 30) {
        return `last ${diff} days`;
      } else {
        return this.fancyDates2(data);
      }
    } else {
      return this.fancyDates2(data);
    }
  }

  fancyDates2(data: StatRequestData): string {
    if (!data || !data.start || !data.end) {
      return "";
    }
    const start = moment(data.start);
    const end = moment(data.end);
    if (start.year() == moment().year()) {
      return `${start.format("D MMM")} - ${end.format("D MMM")}`;
    } else {
      return `${start.format("YYYY MMM")} - ${end.format("YYYY MMM")}`;
    }
  }

  configureStatOption(index: number, data: StatRequestData) {
    this.currentConfiguringOption = index;
    this.currentConfiguringOptionStartDate = data.start;
    this.currentConfiguringOptionEndDate = data.end;
  }

  updateStats() {
    switch (this.currentConfiguringOption) {
      case 1: {
        this.allNewBrandUsersData.start = this.currentConfiguringOptionStartDate;
        this.allNewBrandUsersData.end = this.currentConfiguringOptionEndDate;
        this.getAllNewBrandusers();
        break;
      }
      case 2: {
        this.allNewWreamsData.start = this.currentConfiguringOptionStartDate;
        this.allNewWreamsData.end = this.currentConfiguringOptionEndDate;
        this.getAllProductWreamCount();
        break;
      }
      case 3: {
        this.allNewVisitorsData.start = this.currentConfiguringOptionStartDate;
        this.allNewVisitorsData.end = this.currentConfiguringOptionEndDate;
        this.getAllBrandNewVisitors();
        break;
      }
      case 4: {
        this.allProductReservesData.start = this.currentConfiguringOptionStartDate;
        this.allProductReservesData.end = this.currentConfiguringOptionEndDate;
        this.getAllProductWreamReservesCount();
        break;
      }
      case 5: {
        this.allProductRevenuesData.start = this.currentConfiguringOptionStartDate;
        this.allProductRevenuesData.end = this.currentConfiguringOptionEndDate;
        this.getAllProductWreamRevenues();
        break;
      }
      default: {
        break;
      }
    }
    this.currentConfiguringOption = 0;
  }

  colChange() {
    (function ($) {
      var $window = $(window),
        $html = $("html");

      $window
        .resize(function resize() {
          if ($window.width() > 1629) {
            $(".col-change").addClass("col-lg-3");
            $(".col-change").removeClass("col-lg-4");
          } else {
            $(".col-change").removeClass("col-lg-3");
            $(".col-change").addClass("col-lg-4");
          }

          $html.removeClass("mobile");
        })
        .trigger("resize");
    })($);
  }

  closePopup() {
    this.currentConfiguringOption = 0;
  }
}
