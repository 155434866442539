import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../config";
import { Brand } from "../models/brand";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class BrandService {
  constructor(public httpClient: HttpClient) {}

  getAllBrands(): Observable<any> {
    const ep = AppConfig.apiUrl("brands/120/0");
    return this.httpClient.get<any>(ep).pipe(map((response) => response.data));
  }

  public deleteBrandById(brandId: any): Observable<any> {
    const ep = AppConfig.apiUrl("brand/delete/" + brandId);
    return this.httpClient.delete<any>(ep).pipe(
      map((response) => response));
  }


  getNextBrands(offset: number): Observable<any> {
    const ep = AppConfig.apiUrl(`brands/120/${offset}`);
    return this.httpClient.get<any>(ep).pipe(map((response) => response.data));
  }

  getFilteredBrands(term: string): Observable<any> {
    const ep = AppConfig.apiUrl(`brands/1000/0/${term}`);
    return this.httpClient.get<any>(ep).pipe(map((response) => response.data));
  }

  public getBrandById(brandId: number): Observable<Brand | null> {
    const ep = AppConfig.apiUrl("brand/" + brandId);
    return this.httpClient.get<any>(ep).pipe(map((response) => response.data));
  }

  public editBrand(data: EditBrandRequest): Observable<Brand> {
    const ep = AppConfig.apiUrl("brand");
    return this.httpClient
      .post<any>(ep, data)
      .pipe(map((response) => response.data));
  }

  public uploadBrandLogo(fileToUpload: any): Observable<any> {
    const ep = AppConfig.apiUrl('upload/brand');
    const formData: FormData = new FormData();
    formData.append("file", fileToUpload);
    return this.httpClient
      .post<any>(ep, formData)
      .pipe(map((response) => response.data));
  }

//   public uploadBrandCover(fileToUpload: any): Observable<any> {
//     const ep = AppConfig.apiUrl('upload/brand');
//     const formData: FormData = new FormData();
//     formData.append("file", fileToUpload);
//     return this.httpClient
//       .post<any>(ep, formData)
//       .pipe(map((response) => response.data));
//   }
// }

public uploadBrandCover(fileToUpload: any): Observable<any> {
  const ep = AppConfig.apiUrl('upload/brand');
  const formData: FormData = new FormData();
  formData.append("file", fileToUpload);
  return this.httpClient
    .post<any>(ep, formData)
    .pipe(map((response) => response.data));
}
}

export interface EditBrandRequest {
  id?: number;
  user_id?: number;
  name: string;
  phone: string;
  address: string;
  email: string;
  website: string;
  description: string;
  opening_hours?: string;
  plan_type: number;
  logo: string;
  cover_photo: string;
  hash_tags: string;
  category: string;
}
