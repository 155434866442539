import {Component, OnInit} from '@angular/core';
import {UserService} from 'src/app/services/user.service';
import {Role} from 'src/app/constants';

declare var $

@Component({
  selector: 'app-all-admin-users',
  templateUrl: './all-admin-users.component.html',
  styleUrls: ['./all-admin-users.component.css']
})
export class AllAdminUsersComponent implements OnInit {
  canEdit = false;
  config: any;
  collection = {count: 0, data: []};
  noAdminUsers = false;

  constructor(private userService: UserService) {
    this.config = {
      itemsPerPage: 12,
      currentPage: 1,
      totalItems: this.collection.count
    };
  }

  ngOnInit() {
    this.userService.getUser().subscribe(user => {
      this.canEdit = user.role == Role.SUPER_ADMIN;
    });
    this.userService.getAllUsers(Role.ADMIN_USER).subscribe(users => {
      if (users) {
        this.collection = {count: users.length, data: users}
      } else {
        this.noAdminUsers = true;
      }
      $('.loader').hide();
    })
  }
}
