import { Component, OnInit } from "@angular/core";
import { BrandService } from "../../../services/brand.service";
import { Router } from "@angular/router";
import { User } from "src/app/models/user";
import { UserService } from "src/app/services/user.service";
import { Brand } from "../../../models/brand";

@Component({
  selector: "app-all-brands",
  templateUrl: "./all-brands.component.html",
  styleUrls: ["./all-brands.component.css"],
})
export class AllBrandsComponent implements OnInit {
  brands;
  config: any;
  collection = { count: 0, data: [] };
  brandImg;
  user: User;
  loading: boolean;
  searchTerm: string;
  isNextDataFetched: boolean = false;
  isFilltered: boolean = false;
  isData: boolean = true;

  constructor(
    private brandService: BrandService,
    private router: Router,
    private userService: UserService
  ) {
    this.config = {
      itemsPerPage: 60,
      currentPage: 1,
      totalItems: this.collection.count,
    };
    this.userService.getUser().subscribe((user: User) => {
      this.user = user;
    });
  }

  ngOnInit() {
    this.loading = true;
    this.isData = true;
    this.brandService.getAllBrands().subscribe((response: Brand[]) => {
      response.length == 0 ? this.isData = false : this.isData = true;

      response.sort((b) => -b.id);
      this.brands = response;
      this.collection = { count: response.length, data: response };
      this.loading = false;
    });
  }

  viewBrand(item) {
    this.router.navigateByUrl("/brand/" + item.id);
  }

  customFilter(term: string) {
    this.isFilltered = true;

    if (term) {
      this.loading = true;
      this.brandService
        .getFilteredBrands(term)
        .subscribe((response: Brand[]) => {
          response.length == 0 ? this.isData = false : this.isData = true;

          response.sort((b) => -b.id);
          this.brands = response;
          this.collection = { count: response.length, data: response };
          this.loading = false;
        });
    } else {
      this.refreshPage();
    }
  }

  refreshPage() {
    this.loading = true;
    this.brandService.getAllBrands().subscribe((response: Brand[]) => {
      response.length == 0 ? this.isData = false : this.isData = true;
      response.sort((b) => -b.id);
      this.brands = response;
      this.collection = { count: response.length, data: response };
      this.loading = false;
    });
    this.isFilltered = false;
  }

  nextData() {
    if (this.isNextDataFetched === false || this.isFilltered === true) {
      this.getNextData();
      this.isNextDataFetched = true;
    }
  }

  getNextData() {
    this.brandService
      .getNextBrands(this.collection.count)
      .subscribe((response: Brand[]) => {
        this.brands.push(...response);
        this.brands.sort((b) => -b.id);
        this.collection = {
          count: this.collection.count + response.length,
          data: this.brands,
        };
        this.isNextDataFetched = false;
      });
  }
}
