import {Component, OnInit} from '@angular/core';
import {UserService} from "../../../services/user.service";
import {CategoryService, EditCategoryRequest} from "../../../services/category.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NotifierService} from "angular-notifier";
import { Role } from 'src/app/constants';
import swal from 'sweetalert';


@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.css']
})

export class EditCategoryComponent implements OnInit {
  show_errors = false;
  status: string = null;
  formData: EditCategoryRequest;
  isUpdating: boolean = false;
  category: EditCategoryRequest;
  showLoader: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private categoryService: CategoryService,
    private notifierService: NotifierService
  ) {
  }

  ngOnInit() {
    this.userService.getUser().subscribe((user) => {
      this.route.params.subscribe(params => {
        if (params.category_id) {
          this.isUpdating = user.role == Role.BRAND_ADMIN || user.role == Role.SUPER_ADMIN;
          this.categoryService.getCategoryById(params.category_id).subscribe(category => {
            this.category = category
            this.initEditExistingCategory(category);
          });
        } else if (params.brand_id) {
          this.initCreatingNewCategory(params.brand_id);
        }
      });
    });
  }

  saveCategory() {
    this.show_errors = true;

    if(!this.validateData()) {
      return;
    }

    this.status = 'saving';

    this.categoryService.editCategory(this.formData).subscribe(res => {
      if(res.success) {
        this.status = 'saved';
        this.notifierService.notify('success', "Category Is Saved!");

        setTimeout(() => {
          this.router.navigate(['/categories/' + this.formData.brand_id])
        }, 1000);
      } else {
        this.status = null;
        this.notifierService.notify('error', res.message);
      }
    }, error => {
      this.status = null;
      this.notifierService.notify('error', error.error);
    });
  }

  removeCategory() {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this file!",
      icon: "warning",
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        this.categoryService.deleteCategoryById(this.category.id).subscribe((res) => {
          if(res.success) {
            swal("Your file has been deleted!", {
              icon: "success",
            });
            this.router.navigateByUrl("/categories/" + this.category.brand_id);
          } else {
            swal(res.message);
          }
        })
      }
    });
  }

  private initEditExistingCategory(category: EditCategoryRequest) {
    this.formData = {
      id: category.id,
      name: category.name,
      brand_id: category.brand_id,
      photo: category.photo
    };
  }

  private initCreatingNewCategory(brandId: number) {
    this.formData = {
      name: "",
      brand_id: brandId,
      photo: ""
    }
  }

  private validateData() {
    if (!this.formData.name) {
      return false;
    }

    if (!this.formData.photo) {
      return false;
    }

    return true;
  }

  chooseCategoryImage(files: File): void {
    if (files && files[0]) {
      this.showLoader = true;

      const file = files[0];

      var iu: any;

      const reader = new FileReader();
      reader.onload = (e) => (iu = reader.result);
      reader.readAsDataURL(file);

      const reader2 = new FileReader();
      reader2.onload = this._uploadCategoryImage.bind(this);
      reader2.readAsBinaryString(file);
    }
  }

  _uploadCategoryImage(readerEvt: any) {
    var binaryString = readerEvt.target.result;
    var base64textString:any = btoa(binaryString);

    this.categoryService.uploadCategoryImage(base64textString).subscribe((url) => {
      this.formData.photo = url.url;
      this.showLoader = false;
    });
  }
}
