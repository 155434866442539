import {Component, OnInit} from '@angular/core';
import {UserService} from "../../../services/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {BannerService} from "../../../services/banner.service";
import {User} from "../../../models/user";
import {Banner} from "../../../models/banner";
import {Role} from "../../../constants";
import {StatRequestData, StatService} from "../../../services/stat.service";
import * as moment from 'moment';
import swal from 'sweetalert';
@Component({
  selector: 'app-view-banner',
  templateUrl: './view-banner.component.html',
  styleUrls: ['./view-banner.component.css']
})

export class ViewBannerComponent implements OnInit {
  banner: Banner;
  canModify = false;
  bannerVisits = 0;
  canRemove: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private bannerService: BannerService,
    private userService: UserService,
    private statService: StatService
  ) {
  }

  ngOnInit() {
    this.userService.getUser().subscribe((user: User) => {
      this.canModify = user.role == Role.BRAND_ADMIN || user.role == Role.SUPER_ADMIN || user.role == Role.ADMIN_USER;
      this.canRemove = user.role == Role.SUPER_ADMIN || user.role == Role.BRAND_ADMIN;
    });

    this.route.params.subscribe(params => {
      if (params.banner_id) {
        this.bannerService.getBannerById(params.banner_id).subscribe((banner: Banner) => {
          this.banner = banner;

          const now = new Date();

          const data: StatRequestData = {
            id: this.banner.id,
            type: "banner",
            start: this.subNDays(now, 365),
            end: now
          };
          
          this.statService.getNewVisitors(data).subscribe(count => {
            this.bannerVisits = count;
          });
        });
      }
    });
  }

  removeBanner() {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this file!",
      icon: "warning",
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {

        this.bannerService.deleteBannerById(this.banner.id).subscribe((res) => {
          if(res.success) {
            swal("Your file has been deleted!", {
              icon: "success",
            });
            this.router.navigateByUrl("/ads/" + this.banner.brand_id);
          } else {
            swal(res.message);
          }
        })
      }
    });
  }

  private subNDays(date: Date, sub: number): Date {
    return moment(date).subtract(sub, 'days').toDate();
  }

}
