import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./components/basic/login/login.component";
import { LayoutComponent } from "./components/basic/layout/layout.component";
import { DashboardComponent } from "./components/basic/dashboard/dashboard.component";
import { NotFoundComponent } from "./components/basic/not-found/not-found.component";
import { AuthGuard } from "./guards/auth.guard";
import { OwnerGuard } from "./guards/owner.guard";
import { Role } from "./constants";
import { AllBrandsComponent } from "./components/brand/all-brands/all-brands.component";
import { ViewBrandComponent } from "./components/brand/view-brand/view-brand.component";
import { EditBrandComponent } from "./components/brand/edit-brand/edit-brand.component";
import { LoginLayoutComponent } from "./components/basic/login-layout/login-layout.component";
import { AllCategoriesComponent } from "./components/category/all-categories/all-categories.component";
import { ViewCategoryComponent } from "./components/category/view-category/view-category.component";
import { EditCategoryComponent } from "./components/category/edit-category/edit-category.component";
import { AllProductsComponent } from "./components/product/all-products/all-products.component";
import { ViewProductComponent } from "./components/product/view-product/view-product.component";
import { EditProductComponent } from "./components/product/edit-product/edit-product.component";
import { AllBannersComponent } from "./components/banner/all-banners/all-banners.component";
import { ViewBannerComponent } from "./components/banner/view-banner/view-banner.component";
import { EditBannerComponent } from "./components/banner/edit-banner/edit-banner.component";
import { EditOfferComponent } from "./components/offer/edit-offer/edit-offer.component";
import { AllOffersComponent } from "./components/offer/all-offers/all-offers.component";
import { ViewOfferComponent } from "./components/offer/view-offer/view-offer.component";
import { AllAdminUsersComponent } from "./components/admin/all-admin-users/all-admin-users.component";
import { AllBrandUsersComponent } from "./components/admin/all-brand-users/all-brand-users.component";
import { EditBrandUserComponent } from "./components/admin/edit-brand-user/edit-brand-user.component";
import { ProfileComponent } from "./components/admin/profile/profile.component";
import { EditAdminUserComponent } from "./components/admin/edit-admin-user/edit-admin-user.component";
import { PendingProductsComponent } from "./components/admin/pending-products/pending-products.component";
import { PendingBannersComponent } from "./components/admin/pending-banners/pending-banners.component";
import { PendingOffersComponent } from "./components/admin/pending-offers/pending-offers.component";
import { AnalyticsComponent } from "./components/admin/analytics/analytics.component";
import { OverallAnalyticsComponent } from "./components/admin/overall-analytics/overall-analytics.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full",
  },
  {
    path: "",
    component: LoginLayoutComponent,
    children: [
      {
        path: "login",
        component: LoginComponent,
      },
    ],
  },
  {
    path: "",
    component: LayoutComponent,
    canActivate: [AuthGuard, OwnerGuard],
    data: {
      roles: [
        Role.BRAND_USER,
        Role.BRAND_ADMIN,
        Role.ADMIN_USER,
        Role.SUPER_ADMIN,
      ],
    },
    children: [
      {
        path: "home",
        component: DashboardComponent,
      },
      {
        path: "products/:brand_id",
        component: AllProductsComponent,
      },
      {
        path: "categories/:brand_id",
        component: AllCategoriesComponent,
      },
      {
        path: "ads/:brand_id",
        component: AllBannersComponent,
      },
      {
        path: "offers/:brand_id",
        component: AllOffersComponent,
      },
      {
        path: "offers/for-product/:product_id",
        component: AllOffersComponent,
      },
      {
        path: "brand/:id",
        component: ViewBrandComponent,
      },
      // {
      //   path: 'category/:category_id',
      //   component: ViewCategoryComponent,
      // },
      {
        path: "product/:product_id",
        component: ViewProductComponent,
      },
      {
        path: "ad/:banner_id",
        component: ViewBannerComponent,
      },
      {
        path: "offer/:offer_id",
        component: ViewOfferComponent,
      },
      {
        path: "profile",
        component: ProfileComponent,
      },
    ],
  },
  {
    path: "",
    component: LayoutComponent,
    canActivate: [AuthGuard, OwnerGuard],
    data: { roles: [Role.BRAND_ADMIN, Role.SUPER_ADMIN] },
    children: [
      {
        path: "edit-brand-user/:brand_user_id",
        component: EditBrandUserComponent,
      },
      {
        path: "create-brand-user/:brand_id",
        component: EditBrandUserComponent,
      },
      {
        path: "edit-brand/:brand_id",
        component: EditBrandComponent,
      },
    ],
  },
  {
    path: "",
    component: LayoutComponent,
    canActivate: [AuthGuard, OwnerGuard],
    data: { roles: [Role.BRAND_ADMIN, Role.ADMIN_USER, Role.SUPER_ADMIN] },
    children: [
      {
        path: "brand-users/:brand_id",
        component: AllBrandUsersComponent,
      },
      {
        path: "create-category/:brand_id",
        component: EditCategoryComponent,
      },
      {
        path: "edit-category/:category_id",
        component: EditCategoryComponent,
      },
      {
        path: "create-product/:brand_id",
        component: EditProductComponent,
      },
      {
        path: "edit-product/:product_id",
        component: EditProductComponent,
      },
      {
        path: "edit-ads/:banner_id",
        component: EditBannerComponent,
      },
      {
        path: "duplicate-ads/:banner_id",
        component: EditBannerComponent,
      },
      {
        path: "create-ads/:brand_id",
        component: EditBannerComponent,
      },
      {
        path: "create-offer/:product_id",
        component: EditOfferComponent,
      },
      {
        path: "edit-offer/:offer_id",
        component: EditOfferComponent,
      },
      {
        path: "duplicate-offer/:offer_id",
        component: EditOfferComponent,
      }
    ],
  },
  {
    path: "",
    component: LayoutComponent,
    canActivate: [AuthGuard, OwnerGuard],
    data: { roles: [Role.ADMIN_USER, Role.SUPER_ADMIN] },
    children: [
      {
        path: "admin-users",
        component: AllAdminUsersComponent,
      },
      {
        path: "brands",
        component: AllBrandsComponent,
      },
      {
        path: "create-brand",
        component: EditBrandComponent,
      },
    ],
  },
  {
    path: "",
    component: LayoutComponent,
    canActivate: [AuthGuard, OwnerGuard],
    data: { roles: [Role.SUPER_ADMIN] },
    children: [
      {
        path: "create-admin-user",
        component: EditAdminUserComponent,
      },
      {
        path: "edit-admin-user/:admin_user_id",
        component: EditAdminUserComponent,
      },
      {
        path: "create-brand",
        component: EditBrandComponent,
      },
      {
        path: "pending-products",
        component: PendingProductsComponent,
      },
      {
        path: "pending-ads",
        component: PendingBannersComponent,
      },
      {
        path: "pending-offers",
        component: PendingOffersComponent,
      },
      {
        path: "analytics/:brand_id",
        component: AnalyticsComponent,
      },
      {
        path: "overall-analytics",
        component: OverallAnalyticsComponent
      }
    ],
  },
  {
    path: "**",
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: "enabled" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
