import { Component, OnInit } from '@angular/core';
import {UserService} from "../../../services/user.service";
import {ProductService} from "../../../services/product.service";
import {Role, VerifyStatus} from "../../../constants";
import {Product} from "../../../models/product";
import {NotifierService} from "angular-notifier";

@Component({
  selector: 'app-pending-products',
  templateUrl: './pending-products.component.html',
  styleUrls: ['./pending-products.component.css']
})
export class PendingProductsComponent implements OnInit {

  approvingId = null;
  rejectingId = null;
  pendingProducts: Product[];
  collection: {count:number, data:Product[]} = {count: 0, data: []};
  config = {
    id: 'pagination1',
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: this.collection.count
  };

  constructor(
    private userService: UserService,
    private productService: ProductService,
    private notifierService: NotifierService
  ) { }

  ngOnInit() {
    this.userService.getUser().subscribe(user => {
      if(user.role === Role.SUPER_ADMIN) {
        this.getPendingProducts();
      }
    });
  }

  getPendingProducts() {
    this.productService.getPendingProducts().subscribe(products => {
      this.pendingProducts = products;
      this.collection = {count: products.length, data: products}
    })
  }

  approveProduct(product_id: number) {
    this.approvingId = product_id;
    this.productService.changeStatus({'id': product_id, 'status': VerifyStatus.APPROVED}).subscribe(() => {
      this.approvingId = null;
      this.getPendingProducts();
      this.notifierService.notify('success', "Product Approved");
    }, () => {
      this.approvingId = null;
    });
  }

  rejectProduct(product_id: number) {
    this.rejectingId = product_id;
    this.productService.changeStatus({'id': product_id, 'status': VerifyStatus.REJECTED}).subscribe(() => {
      this.rejectingId = null;
      this.getPendingProducts();
      this.notifierService.notify('success', "Product Rejected");
    }, () => {
      this.rejectingId = null;
    });
  }
}
