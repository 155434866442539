import {Component, OnInit} from '@angular/core';
import {UserService} from "../../../services/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {OfferService} from "../../../services/offer.service";
import {User} from "../../../models/user";
import {Offer} from "../../../models/offer";
import {Role} from "../../../constants";
import * as moment from 'moment';
import {StatRequestData, StatService} from "../../../services/stat.service";
import swal from 'sweetalert';

@Component({
  selector: 'app-view-offer',
  templateUrl: './view-offer.component.html',
  styleUrls: ['./view-offer.component.css']
})
export class ViewOfferComponent implements OnInit {

  offer: Offer;
  canModify = false;
  offerVisits = 0;
  canRemove: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private offerService: OfferService,
    private userService: UserService,
    private statService: StatService
  ) {
  }

  ngOnInit() {
    this.userService.getUser().subscribe((user: User) => {
      this.canModify = user.role == Role.BRAND_ADMIN || user.role == Role.SUPER_ADMIN || user.role == Role.ADMIN_USER;
      this.canRemove = user.role == Role.BRAND_ADMIN || user.role == Role.SUPER_ADMIN;
    });
    
    this.route.params.subscribe(params => {
      if (params.offer_id) {
        this.offerService.getOfferById(params.offer_id).subscribe((offer: Offer) => {
          this.offer = offer;

          const now = new Date();
          const data: StatRequestData = {
            id: this.offer.id,
            type: "offer",
            start: this.subNDays(now, 365),
            end: now
          };
          this.statService.getNewVisitors(data).subscribe(count => {
            this.offerVisits = count;
          });
        });
      }
    });
  }

  removeOffer() {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this file!",
      icon: "warning",
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {

        this.offerService.deleteOfferById(this.offer.id).subscribe((res) => {
          if(res.success) {
            swal("Offer has been deleted!", {
              icon: "success",
            });
            this.router.navigateByUrl("/offers/" + this.offer.product.brand_id);
          } else {
            swal(res.message);
          }
        })
      }
    });
  }

  private subNDays(date: Date, sub: number): Date {
    return moment(date).subtract(sub, 'days').toDate();
  }
}
