import {Injectable} from '@angular/core';
import {Observable} from "rxjs/internal/Observable";
import {AppConfig} from "../config";
import {Category} from "../models/category";
import {HttpClient} from "@angular/common/http";
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class CategoryService {
  constructor(private httpClient: HttpClient) { }

  public getCategoryById(categoryId: number): Observable<EditCategoryRequest | null> {
    const ep = AppConfig.apiUrl('brand/category/' + categoryId);
    return this.httpClient.get<any>(ep).pipe(map(response => response.data));
  }

  public deleteCategoryById(categoryId: any): Observable<any> {
    const ep = AppConfig.apiUrl("category/delete/" + categoryId);
    return this.httpClient.delete<any>(ep).pipe(
      map((response) => response));
  }

  public getCategoriesForBrand(brandId: number): Observable<Category[]> {
    const ep = AppConfig.apiUrl('brand/' + brandId + '/categories');
    return this.httpClient.get<any>(ep).pipe(map(response => response.data));
  }

  public getAllCategories(): Observable<Category[]> {
    const ep = AppConfig.apiUrl('categories/all');
    return this.httpClient.get<any>(ep).pipe(map(response => response.data));
  }

  public editCategory(data: EditCategoryRequest): Observable<any> {
    const ep = AppConfig.apiUrl('brand/category');
    
    return this.httpClient.post<any>(ep, data).pipe(map(response => response));
  }

  public uploadCategoryImage(fileToUpload: any): Observable<any> {
    const ep = AppConfig.apiUrl('upload/category');
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload);
    return this.httpClient.post<any>(ep, formData).pipe(map(response => response.data));
  }
}

export interface EditCategoryRequest {
  id?: number,
  brand_id: number,
  name: string
  photo: string
}
