import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {EditBrandUserRequest, UserService} from "../../../services/user.service";
import {Role} from "../../../constants";
import {NotifierService} from "angular-notifier";
import {User} from "../../../models/user";
import { ValidateService } from 'src/app/services/validate.service';

@Component({
  selector: 'app-edit-brand-user',
  templateUrl: './edit-brand-user.component.html',
  styleUrls: ['./edit-brand-user.component.css']
})
export class EditBrandUserComponent implements OnInit {

  show_errors = false;
  status: string = null;
  formData: EditBrandUserRequest;
  isValidEmail:Boolean = false
  isValidPassword: Boolean = false

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private notifierService: NotifierService,
    private validateService: ValidateService
  ) { }

  ngOnInit() {
    this.userService.getUser().subscribe(user => {
      this.route.params.subscribe( params => {
        if(params.brand_user_id) {
          this.userService.getBrandUserByUserId(params.brand_user_id).subscribe((brandUser: any) => {
            this.initEditExistingBrandUser(brandUser);
          });
        } else if(user.role === Role.BRAND_ADMIN) {
          this.initCreatingNewBrandUser(user.brand.id);
        } else if(user.role === Role.SUPER_ADMIN && params.brand_id) {
          this.initCreatingNewBrandUser(params.brand_id);
        }
      });
    });
  }

  private initEditExistingBrandUser(brandUser: User) {
    this.formData = {
      id: brandUser.id,
      name: brandUser.name,
      email: brandUser.email,
      password: "",
      brand_id: brandUser.viewable_brands[0].id,
      role: Role.BRAND_USER
    };
  }

  private initCreatingNewBrandUser(brandId) {
    this.formData = {
      name: "",
      email: "",
      password: "",
      brand_id: brandId,
      role: Role.BRAND_USER
    }
  }

  private validateData() {
    if(!this.formData.name) {
      return false;
    }
    if(!this.formData.email) {
      return false;
    }
    if(!this.formData.id && !this.formData.password) {
      return false;
    }

    return true;
  }
  validateEmail() {
    this.isValidEmail = this.validateService.validateEmail(this.formData.email);
    return this.isValidEmail;
  }

  validatePassword() {
    this.isValidPassword = !this.formData.password || this.validateService.validatePassword(this.formData.password);
    return this.isValidPassword;
  }
  save() {
    this.show_errors = true;
    if(!this.validateData() || !this.validatePassword() || !this.validateEmail()) {
      return;
    }
    this.status = 'saving';
    this.userService.editBrandUser(this.formData).subscribe(res => {
      this.status = 'saved';
      this.notifierService.notify('success', "Brand User Saved");
      setTimeout(() => {
        this.router.navigate(['/brand-users/' + this.formData.brand_id])
      }, 1000);
    }, error => {
      this.status = null;
      this.notifierService.notify('error', error.error);
    });
  }
}
