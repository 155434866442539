import {Component, OnInit} from '@angular/core';
import {CategoryService} from "../../../services/category.service";
import {User} from "../../../models/user";
import {UserService} from "../../../services/user.service";
import {Category} from "../../../models/category";
import {ActivatedRoute} from "@angular/router";
import {Util} from "../../../util";
import {Role} from "../../../constants";

@Component({
  selector: 'app-all-categories',
  templateUrl: './all-categories.component.html',
  styleUrls: ['./all-categories.component.css']
})

export class AllCategoriesComponent implements OnInit {
  canEdit = false;
  brandCategories: Category[];
  brandId;
  user:User

  constructor(
    private categoryService: CategoryService,
    private userService: UserService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.userService.getUser().subscribe((response: User) => {
      this.user = response;
      this.canEdit = (response.role == Role.BRAND_ADMIN || response.role == Role.SUPER_ADMIN || response.role == Role.ADMIN_USER);

      this.route.params.subscribe(params => {
        this.brandId = Util.getBrandIdBasedOnRole(response, params);
        this.categoryService.getCategoriesForBrand(this.brandId).subscribe((response: Category[]) => {
          this.brandCategories = response;
        });
      });
    });
  }

}
