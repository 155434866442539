import {Component, OnInit} from '@angular/core';
import {ProductService} from "../../../services/product.service";
import {Product} from "../../../models/product";
import {User} from "../../../models/user";
import {UserService} from "../../../services/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Role} from "../../../constants";
import {CategoryService} from "../../../services/category.service";
import {Category} from "../../../models/category";
import {Util} from "../../../util";
import {StatRequestData, StatService} from "../../../services/stat.service";
import * as moment from "moment";

enum SortType {
  COST_INC,
  COST_DEC,
  RECENT,
  TOP_WREAMS
}

@Component({
  selector: 'app-all-products',
  templateUrl: './all-products.component.html',
  styleUrls: ['./all-products.component.css']
})
export class AllProductsComponent implements OnInit {

  filteringCategoryId = 0;
  filteringStatus = "ANY";
  products: Product[] = [];
  categories: Category[] = [];
  filteredProducts: Product[] = [];
  displayedProducts: Product[] = [];
  displayedPageCount = 1;
  itemsPerPage = 50;
  brandId = 8;
  canCreate = false;
  sortTypes = SortType;
  sortingType: string = SortType.RECENT.toString();
  loading: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private productService: ProductService,
    private categoryService: CategoryService,
    private statService: StatService
  ) { }

  ngOnInit() {
    this.getAllProducts();
  }

  async getAllProducts() {
    this.loading = true;

    this.userService.getUser().subscribe((user: User) => {
      if (user.role == Role.BRAND_ADMIN || user.role == Role.SUPER_ADMIN || user.role == Role.ADMIN_USER) {
        this.canCreate = true;
      }

      this.route.params.subscribe(params => {
        this.brandId = Util.getBrandIdBasedOnRole(user, params);       

        if (this.brandId) {
          if(this.productService.currentListedBrand == this.brandId) {
            this.products = this.productService.currentListedProducts;
            this.sortProducts();
            this.loading = false;
          } else {
            this.productService.currentListedBrand = this.brandId;

            this.categoryService.getCategoriesForBrand(this.brandId).subscribe((categories: Category[]) => {
              this.categories = categories;
            });
  
            this.productService.getProductsForBrand(this.brandId).subscribe((response: Product[]) => {
              this.products = response;
              this.productService.currentListedProducts = response;

              this.sortProducts();
  
              this.loading = false;
            });
          }
        }
      });
    });
  }

  selectProduct(id) {
    this.router.navigateByUrl('product/' + id)
  }

  filterProducts() {
    this.filteredProducts = this.products.filter(product => {
      let status = true;
      status = status && (this.filteringCategoryId == 0 || this.filteringCategoryId == product.category_id);
      status = status && (this.filteringStatus == "ANY" || this.filteringStatus == product.status || (this.filteringStatus == "PENDING" && !product.status));
      return status;
    });

    this.displayedPageCount = 1;

    this.updateDisplayProducts();
  }

  private updateDisplayProducts() {
    this.displayedProducts = this.filteredProducts.slice(0, this.displayedPageCount * this.itemsPerPage);
  }

  loadMoreItems() {
    this.displayedPageCount += 1;
    this.updateDisplayProducts();
  }

  hasMore() {
    return this.displayedPageCount * this.itemsPerPage < this.filteredProducts.length;
  }

  sortProducts() {
    function getAttribute(p: Product) {
      switch (type) {
        case SortType.RECENT:
          return -(new Date(p.created_at).getTime());
        case SortType.COST_INC:
          return p.price;
        case SortType.COST_DEC:
          return -p.price;
        case SortType.TOP_WREAMS:
          return p.wishlist_count;
      }
    }

    const type = parseInt(this.sortingType);
    this.products.sort((a, b) => getAttribute(a) > getAttribute(b) ? 1 : (getAttribute(a) < getAttribute(b) ? -1 : 0));
    this.filterProducts();
  }

  private subNDays(date: Date, sub: number): Date {
    return moment(date).subtract(sub, 'days').toDate();
  }
}
