import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AppConfig} from "../config";
import {Observable} from "rxjs/internal/Observable";
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StatService {

  constructor(private httpClient: HttpClient) {
  }

  public getProductWreamRevenues(data: StatRequestData): Observable<number> {
    const ep = AppConfig.apiUrl('new-wreams-revenues');
    return this.httpClient.post<any>(ep, data).pipe(map(response => response.data));
  }

  public getAllProductWreamRevenues(data: any): Observable<number> {
    const ep = AppConfig.apiUrl('all-new-wreams-revenues');
    return this.httpClient.post<any>(ep, data).pipe(map(response => response.data));
  }

  public getProductWreamCount(data: StatRequestData): Observable<number> {
    const ep = AppConfig.apiUrl('new-wreams');
    return this.httpClient.post<any>(ep, data).pipe(map(response => response.data));
  }

  public getAllProductWreamCount(data: OverallStatRequestData): Observable<number> {
    const ep = AppConfig.apiUrl('all-new-wreams');
    return this.httpClient.post<any>(ep, data).pipe(map(response => response.data));
  }

  public getProductWreamReservesCount(data: StatRequestData): Observable<number> {
    const ep = AppConfig.apiUrl('new-wreams-reserved');
    return this.httpClient.post<any>(ep, data).pipe(map(response => response.data));
  }

  public getAllProductWreamReservesCount(data: OverallStatRequestData): Observable<number> {
    const ep = AppConfig.apiUrl('all-new-wreams-reserved');
    return this.httpClient.post<any>(ep, data).pipe(map(response => response.data));
  }

  public getNewVisitors(data: StatRequestData): Observable<number> {
    const ep = AppConfig.apiUrl('visitors');
    return this.httpClient.post<any>(ep, data).pipe(map(response => response.data));
  }

  public getAllNewVisitors(data: OverallStatRequestData): Observable<number> {
    const ep = AppConfig.apiUrl('all-visitors');
    return this.httpClient.post<any>(ep, data).pipe(map(response => response.data));
  }

  public getBrandNewVisitors(data: StatRequestData): Observable<number> {
    const ep = AppConfig.apiUrl('new-visitors');
    return this.httpClient.post<any>(ep, data).pipe(map(response => response.data));
  }

  public getAllBrandNewVisitors(data: OverallStatRequestData): Observable<number> {
    const ep = AppConfig.apiUrl('all-new-visitors');
    return this.httpClient.post<any>(ep, data).pipe(map(response => response.data));
  }

  public getTopWreams(): Observable<any> {
    const ep = AppConfig.apiUrl('top-wreams');
    return this.httpClient.get<any>(ep).pipe(map(response => response.data));
  }
}

export interface StatRequestData {
  id: number,
  type?: string,
  start: Date,
  end: Date,
}

export interface OverallStatRequestData {
  type?: string,
  start: Date,
  end: Date,
}
