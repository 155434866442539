import {Component, OnInit} from '@angular/core';
import {BannerService} from "../../../services/banner.service";
import {Banner} from "../../../models/banner";
import {UserService} from "../../../services/user.service";
import {User} from "../../../models/user";
import {ActivatedRoute, Router} from "@angular/router";
import {Role} from "../../../constants";
import {Util} from "../../../util";
@Component({
  selector: 'app-all-banners',
  templateUrl: './all-banners.component.html',
  styleUrls: ['./all-banners.component.css']
})
export class AllBannersComponent implements OnInit {

  canEdit = false;
  filteringStatus = "ANY";
  brandId = null;
  banners: Banner[] = [];
  filteredBanners: Banner[] = [];
  displayedBanners: Banner[] = [];
  displayedPageCount = 1;
  itemsPerPage = 6;
  loading: boolean;

  constructor(
    private bannerService: BannerService,
    private userService: UserService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.getAllBanners();
  }

  getAllBanners() {
    this.loading = true;
    this.userService.getUser().subscribe((user: User) => {
      this.canEdit = user.role == Role.SUPER_ADMIN || user.role == Role.BRAND_ADMIN || user.role == Role.ADMIN_USER;
      this.route.params.subscribe( params => {
        this.brandId = Util.getBrandIdBasedOnRole(user, params);
        if(this.brandId) {
          this.bannerService.getBannersForBrand(this.brandId).subscribe((response: Banner[]) => {
            this.banners = response;
            this.filterBanners();
            this.loading = false;
          });
        }
      });
    });
  }

  filterBanners() {
    this.filteredBanners = this.banners.filter(banner => {
      let status = true;
      status = status && (!this.brandId || this.brandId == banner.brand_id);
      status = status && (this.filteringStatus == "ANY" || this.filteringStatus == banner.status);
      return status;
    });
    this.displayedPageCount = 1;
    this.updateDisplayBanners();
  }

  private updateDisplayBanners() {
    this.displayedBanners = this.filteredBanners.slice(0, this.displayedPageCount * this.itemsPerPage)
  }

  loadMore() {
    this.displayedPageCount += 1;
    this.updateDisplayBanners();
  }

  hasMore() {
    return this.displayedPageCount * this.itemsPerPage < this.filteredBanners.length;
  }

}
