import {Injectable} from '@angular/core';
import {AppConfig} from "../config";
import {HttpClient} from "@angular/common/http";
import {catchError, map, tap} from "rxjs/operators";
import {Observable} from "rxjs/internal/Observable";
import {User} from "../models/user";
import {of} from "rxjs/internal/observable/of";
import {AppResponse} from "../models/response";
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private user: User;

  constructor(private httpClient: HttpClient,private jwtHelperService: JwtHelperService) {
  }

  private static onLogin(token: string) {
    UserService.setToken(token);
  }
  loggedIn() {
		const token: string = this.jwtHelperService.tokenGetter();

		if (!token) {
			return false;
		}

		const tokenExpired: boolean = this.jwtHelperService.isTokenExpired(token);

		return !tokenExpired;
	}
  static getToken() {
    return localStorage.getItem('token');
  }

  private static setToken(token) {
    localStorage.setItem('token', token);
  }

  getUserPrivileges(){
    return this.getUser()['role']
  }

  getUser(): Observable<User> {
    if (this.user) {
      return of(this.user);
    }
    const token = UserService.getToken();
    if (!token) {
      return of(null);
    }
    const ep = AppConfig.apiUrl('get-own-user');
    return this.httpClient.get<User>(ep).pipe(tap((res: any) => {
      this.user = res['data'];
    })).pipe(map(response => response.data));
  }

  login(email: string, password: string): Observable<AppResponse> {
    interface T {
      access_token: string;
    }

    const url = AppConfig.apiUrl('login');
    const data = {username: email, password: password};
    return this.httpClient.post<T>(url, data).pipe(map(res => {
        if (res['data'].access_token) {
          UserService.onLogin(res['data'].access_token);
          return {success: true, message: null};
        };

        if(res['status'] == 400){
          return {success: false, message: null};
        }

      }),
      catchError((err, caught) => {
        return of({success: false, message: err.toString()});
      }));
  }

  public logout() {
    this.user = null;
    localStorage.removeItem('token');
  }

  public getAllUsers(type): Observable<User[]> {
    const ep = AppConfig.apiUrl('users/'+type);
    return this.httpClient.get<any>(ep).pipe(map(response => response.data));
  }
  public getBrandUsers(brandId: Number): Observable<User[]> {
    const ep = AppConfig.apiUrl('brand/'+brandId+"/brand-users");
    return this.httpClient.get<any>(ep).pipe(map(response => response.data));
  }

  public getBrandAdminByUserId(userId) {
    const ep = AppConfig.apiUrl('brand-admin/' + userId);
    return this.httpClient.get<User>(ep).pipe(map(response => response.data));
  }

  public getBrandUserByUserId(userId) {
    const ep = AppConfig.apiUrl('brand-user/' + userId);
    return this.httpClient.get<User>(ep).pipe(map(response => response.data));
  }

  public getAdminUserByUserId(userId) {
    const ep = AppConfig.apiUrl('admin-user/' + userId);
    return this.httpClient.get<User>(ep).pipe(map(response => response.data));
  }

  public editUser(data) {
    const ep = AppConfig.apiUrl('brand-admin');
    return this.httpClient.post<User>(ep, data).pipe(map(response => response.data));
  }

  public editBrandUser(data) {
    const ep = AppConfig.apiUrl('brand-user');
    return this.httpClient.post<User>(ep, data).pipe(map(response => response.data));
  }

  public editAdminUser(data) {
    const ep = AppConfig.apiUrl('admin-user');
    return this.httpClient.post<User>(ep, data).pipe(map(response => response.data));
  }
}

export interface EditUserRequest {
  id?: number;
  name: string;
  email: string;
  password: string;
  role: string;
}

export interface EditBrandUserRequest extends EditUserRequest {
  brand_id: number
}

export interface EditAdminUserRequest extends EditUserRequest { }
