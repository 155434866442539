import { Component, OnInit } from '@angular/core';
import {UserService} from "../../../services/user.service";
import {OfferService} from "../../../services/offer.service";
import {Role, VerifyStatus} from "../../../constants";
import {Offer} from "../../../models/offer";
import {NotifierService} from "angular-notifier";

enum SortColumn {
  OFFER_NAME,
  BRAND_NAME,
  PRODUCT_NAME,
  START_DATE,
  END_DATE
}

@Component({
  selector: 'app-pending-offers',
  templateUrl: './pending-offers.component.html',
  styleUrls: ['./pending-offers.component.css']
})
export class PendingOffersComponent implements OnInit {

  sortColumns = SortColumn;
  sortColumn: SortColumn;
  sortAscending = true;
  approvingId = null;
  rejectingId = null;
  pendingOffers: Offer[];
  collection: {count:number, data:Offer[]} = {count: 0, data: []};
  config = {
    id: 'pagination1',
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: this.collection.count
  };

  constructor(
    private userService: UserService,
    private offerService: OfferService,
    private notifierService: NotifierService
  ) { }

  ngOnInit() {
    this.userService.getUser().subscribe(user => {
      if(user.role === Role.SUPER_ADMIN) {
        this.getPendingOffers();
      }
    });
  }

  getPendingOffers() {
    this.offerService.getPendingOffers().subscribe(offers => {
      this.pendingOffers = offers;
      this.sortBanners(SortColumn.START_DATE);
      this.collection = {count: offers.length, data: this.pendingOffers}
    })
  }

  approveOffer(offer_id: number) {
    this.approvingId = offer_id;
    this.offerService.changeStatus({'id': offer_id, 'status': VerifyStatus.APPROVED}).subscribe(() => {
      this.approvingId = null;
      this.getPendingOffers();
      this.notifierService.notify('success', "Offer Approved");
    }, () => {
      this.approvingId = null;
    });
  }

  rejectOffer(offer_id: number) {
    this.rejectingId = offer_id;
    this.offerService.changeStatus({'id': offer_id, 'status': VerifyStatus.REJECTED}).subscribe(() => {
      this.rejectingId = null;
      this.getPendingOffers();
      this.notifierService.notify('success', "Offer Rejected");
    }, () => {
      this.rejectingId = null;
    });
  }

  sortBanners(sortingType) {
    if(this.sortColumn == sortingType) {
      this.sortAscending = !this.sortAscending;
    }
    this.sortColumn = sortingType;
    function getAttribute(p: Offer) {
      switch (type) {
        case SortColumn.OFFER_NAME:
          return p.title;
        case SortColumn.BRAND_NAME:
          return p.product.brand.name;
        case SortColumn.PRODUCT_NAME:
          return p.product.title;
        case SortColumn.START_DATE:
          return (new Date(p.start_date).getTime());
        case SortColumn.END_DATE:
          return (new Date(p.end_date).getTime());
      }
    }
    function sort(a, b, ascending) {
      const m = ascending ? 1 : -1;
      const attrA = getAttribute(a);
      const attrB = getAttribute(b);
      return m*(attrA < attrB ? 1 : (attrA > attrB ? -1 : 0))
    }

    const type = parseInt(sortingType);
    this.pendingOffers.sort((a, b) => sort(a, b, this.sortAscending));
  }
}
