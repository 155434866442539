import {User} from "./models/user";
import {Params} from "@angular/router";
import {Role} from "./constants";

export namespace Util {

  export function getBrandIdBasedOnRole(user: User, params: Params | null): number | null {
    if((user.role == Role.SUPER_ADMIN || user.role == Role.ADMIN_USER) && params && params.brand_id) {
      return params.brand_id;
    } else if (user.role == Role.BRAND_ADMIN && user.brand) {
      return user.brand.id;
    } else if (user.role == Role.BRAND_USER && user.viewable_brands.length) {
      return user.viewable_brands[0].id;
    } else {
      return null;
    }
  }

  export function getFileName(url: string) {
    if(url) {
      const index = url.lastIndexOf("/");
      if(0 < index) {
        return url.slice(index+1)
      }
    }
    return null;
  }
}
